import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, of, Subscription} from 'rxjs';
import {switchMap } from 'rxjs/operators';
import {Survey} from '../../../models/survey';
import {
  CreateSurveySuccessAction,
  DeleteSurveySuccessAction,
  UpdateSurveySuccessAction,
} from '../../store/survey/survey.action';
import {selectSurveysSynced} from '../../store/survey/survey.selector';
import {convertDocumentSnapshotToEntity} from '../../utils/firebase/convert-document-snapshot-to-entity';
import { Firestore } from '@angular/fire/firestore';
import { collectionChanges } from 'rxfire/firestore';
import { collection, CollectionReference, query, where, DocumentChange } from '@angular/fire/firestore';
import { Auth, user } from '@angular/fire/auth';
import { selectCurrentUser } from '../../store/users/users.selector';

@Injectable({
  providedIn: 'root',
})

export class SurveysSyncService implements OnDestroy {
  private subscription: Subscription;

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private store$: Store<{}>
  ) {}

  public init() {
    this.subscription = this.subscribeToSurveysChanges();
  }

  private subscribeToSurveysChanges(): Subscription {
    console.log('SURVEYS SUBSCRIBE')
    return combineLatest([this.store$.pipe(select(selectSurveysSynced))])
      .pipe(
        switchMap(([synced]) => {
          if (!synced) {
            return of([]);
          }
          return collectionChanges<Survey>(
            query<Survey>(
              collection(this.firestore, "surveys") as CollectionReference<Survey> //, where('createdByUserId', '==', user.id)
            ) 
            // ||
            // query<Survey>(
            //   collection(this.firestore, "surveys") as CollectionReference<Survey>, where('public', '==', true)
            // )
          )
        })
      )
      .subscribe((actions: DocumentChange<Survey>[]) => {
        if (!actions) {
          return
        } else {
        actions.forEach(action => {
          const survey = convertDocumentSnapshotToEntity<Survey>(action.doc);
          switch (action.type) {
            case 'added':
              return this.store$.dispatch(new CreateSurveySuccessAction({survey}));
            case 'modified':
              return this.store$.dispatch(new UpdateSurveySuccessAction({survey}));
            case 'removed':
              return this.store$.dispatch(new DeleteSurveySuccessAction({surveyId: survey.id}));
          }
        });
      }
    });
  }

  public ngOnDestroy() {
    console.log('SURVEYS SYNC DESTROY')
    if (this.subscription) {
      console.log('survey sync subscription destroy')
      this.subscription.unsubscribe();
    }
  }
  
}