import { Action, ActionReducer } from '@ngrx/store';
import { ActionTypes } from './logout.action';

export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
    return function clearStateFn(state: State, action: Action) {
        if (action.type === ActionTypes.LOGOUT) {
            state = {} as State; // ==> Emptying state here
    }
    return reducer(state, action);
   };
}