import {SuggestionsAction, SuggestionsActionType} from './suggestion.action';
import {initialSuggestionsState, suggestionAdapter, SuggestionsState} from './suggestion.state';

export function suggestionsReducer(state: SuggestionsState = initialSuggestionsState, action: SuggestionsAction): SuggestionsState {
  switch (action.type) {
    case SuggestionsActionType.GET_SUCCESS:
      return suggestionAdapter.upsertMany(action.payload.suggestions, state);
    case SuggestionsActionType.CREATE_SUCCESS:
      return suggestionAdapter.addOne(action.payload.suggestion, state);
    case SuggestionsActionType.UPDATE_SUCCESS:
      return suggestionAdapter.upsertOne(action.payload.suggestion, state);
    case SuggestionsActionType.DELETE_SUCCESS:
      return suggestionAdapter.removeOne(action.payload.suggestionId, state);
    case SuggestionsActionType.SET_SYNCED:
      return {...state, synced: action.payload.synced};
    case SuggestionsActionType.CLEAR:
      return initialSuggestionsState;
    default:
      return state;
  }
}
