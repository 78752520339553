import { selectProjectsLoaded } from './../store/project/project.selector';
import { takeUntil, switchMap, filter } from 'rxjs/operators';
import { selectUsersLoaded } from './../store/users/users.selector';
import { combineLatest, of, Subject } from 'rxjs';
import { ViewService } from './../page/view.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

 
 
@Injectable({
  providedIn: 'root'
})
export class WorkspaceResolver implements Resolve<any> {


    private _onDestroy = new Subject();

    constructor(
        private store$: Store<{}>,
        public router: Router,
        public viewService: ViewService,
    ) {

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

        console.log('WORKSPACE RESOLVER RUNS')

        return new Promise((resolve, reject) => {
            return combineLatest([
                this.store$.select(selectProjectsLoaded),
                this.store$.select(selectUsersLoaded),
            ]).pipe(
              takeUntil(this._onDestroy), 
              switchMap(([projects, usersSynced]) => {
                console.log(usersSynced)
                if (!projects || !usersSynced) {
                  return of ([])
                }
                return of([projects, usersSynced])
              }),
              filter(([projects, usersSynced]) => !!projects && !!usersSynced),
            ).subscribe(([projects, usersSynced]) => {
                if (projects && usersSynced) {
                    this._onDestroy.next();
                    this.viewService.changeDataLoading(false);
                    return resolve(true)
                } else {
                    this._onDestroy.next();
                    this.viewService.changeDataLoading(false);
                    return resolve(this.router.parseUrl('/auth'))
                }
            })
        })

    }

}