import { ProjectsService } from './../projects.service';
import { SetProjectsLoadedAction } from './../../store/project/project.action';
import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, of, Subject, Subscription} from 'rxjs';
import { switchMap, map, filter, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import {Project} from '../../../models/project';
import {
  CreateProjectSuccessAction,
  DeleteProjectSuccessAction,
  UpdateProjectSuccessAction,
} from '../../store/project/project.action';
import {selectProjectsSynced} from '../../store/project/project.selector';
import {convertDocumentSnapshotToEntity} from '../../utils/firebase/convert-document-snapshot-to-entity';
import { Auth, user } from '@angular/fire/auth';
import { onSnapshot, query, where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})

export class ProjectsSyncService implements OnDestroy {

  private subscription: Subscription;
  private _onDestroy = new Subject();
  public unsubscribe: any;

  constructor(
    private auth: Auth,
    private store$: Store<{}>,
    public projectsService: ProjectsService
  ) {}

  public init() {
    this.subscription = this.subscribeToProjectsChanges();   
  }

  private subscribeToProjectsChanges(): Subscription {
    // console.log('ENTITIES SUBSCRIBE')
    return combineLatest([
      this.store$.pipe(select(selectProjectsSynced)), 
      user(this.auth),
    ])
      .pipe(
        distinctUntilChanged(),
        takeUntil(this._onDestroy),
        switchMap(([synced, user]) => {
          if (!synced || !user) {
            return of([]);
          }
          return user.getIdTokenResult().then(t => {
            return [t.claims, user]
          })
        }),
        filter(([claims, user]) => !!claims),
        map(([claims, user]) => {
          console.log(user)
          if (claims.admin) {
            const q = query<Project>(this.projectsService.collectionReference());
            return q
          } else {
            const q = query(this.projectsService.collectionReference(), where(`permissions.${user.uid}`, '>', ''));
            return q
          }
        })
      ).subscribe((q) => {
        if (this.unsubscribe) {
          this.unsubscribe();
        }
        this.unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const project = convertDocumentSnapshotToEntity<Project>(change.doc);
            switch (change.type) {
              case 'added':
                // console.log('project add')
                return this.store$.dispatch(new CreateProjectSuccessAction({project}));
              case 'modified':
                // console.log('peers mod')
                return this.store$.dispatch(new UpdateProjectSuccessAction({project}));
              case 'removed':
                // console.log('peers rem')
                return this.store$.dispatch(new DeleteProjectSuccessAction({projectId: project.id}));
            }
          });
          this.store$.dispatch(new SetProjectsLoadedAction({loaded: true}));
        })
      })
  }

  public ngOnDestroy() {
    this._onDestroy.next();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
  
}