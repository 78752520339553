import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {from, Observable} from 'rxjs';
import {catchError, filter, map, mergeMap, take} from 'rxjs/operators';
import {ResponsesService} from '../../firestore/responses.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  ResponsesActionType,
  CreateResponsesAction,
  DeleteResponseAction,
  UpdateResponseAction,
} from './responses.action';
import {selectResponseById} from './responses.selector';

@Injectable()
export class ResponsesEffects {

  public create$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CreateResponsesAction>(ResponsesActionType.CREATE),
      mergeMap(action => {
        const {surveyId, responses, onSuccess, onFailure} = action.payload;
        return from(
          // responses.length === 1 ?
            this.responsesService.create(surveyId, responses[0])
            // : this.responsesService.createMany(surveyId, responses)
        ).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public update$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<UpdateResponseAction>(ResponsesActionType.UPDATE),
      mergeMap(action => {
        const {surveyId, responseId, response, onSuccess, onFailure} = action.payload;

        return this.responsesService.update(surveyId, responseId, response).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  });

  public delete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeleteResponseAction>(ResponsesActionType.DELETE),
      mergeMap(action => {
        const {surveyId, responseId, onSuccess, onFailure} = action.payload;
        return this.responsesService.delete(surveyId, responseId).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  constructor(private actions$: Actions, private responsesService: ResponsesService) {}
}
