import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Utility} from '../../../models/utility';

export interface UtilitiesState extends EntityState<Utility> {
  synced: boolean;
  loaded: boolean;
}

export const utilitiesAdapter = createEntityAdapter<Utility>({
  // sortComparer: (a, b) =>
  //   a.email.localeCompare(b.email)
});

export const initialUtilitiesState: UtilitiesState = utilitiesAdapter.getInitialState({
  synced: false,
  loaded: false,
});