import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, of, Subscription} from 'rxjs';
import {switchMap } from 'rxjs/operators';
import {App} from '../../../models/app';
import {
  CreateAppSuccessAction,
  DeleteAppSuccessAction,
  UpdateAppSuccessAction,
} from '../../store/app/app.action';
import {selectAppsSynced} from '../../store/app/app.selector';
import {convertDocumentSnapshotToEntity} from '../../utils/firebase/convert-document-snapshot-to-entity';
import { Firestore } from '@angular/fire/firestore';
import { collectionChanges } from 'rxfire/firestore';
import { collection, CollectionReference, query, where, DocumentChange } from '@angular/fire/firestore';
import { Auth, user } from '@angular/fire/auth';
import { selectCurrentUser } from '../../store/users/users.selector';

@Injectable({
  providedIn: 'root',
})

export class AppsSyncService implements OnDestroy {
  private subscription: Subscription;

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private store$: Store<{}>
  ) {}

  public init() {
    this.subscription = this.subscribeToAppsChanges();
  }

  private subscribeToAppsChanges(): Subscription {
    console.log('SURVEYS SUBSCRIBE')
    return combineLatest([this.store$.pipe(select(selectAppsSynced))])
      .pipe(
        switchMap(([synced]) => {
          if (!synced) {
            return of([]);
          }
          return collectionChanges<App>(
            query<App>(
              collection(this.firestore, "apps") as CollectionReference<App> //, where('createdByUserId', '==', user.id)
            ) 
            // ||
            // query<App>(
            //   collection(this.firestore, "apps") as CollectionReference<App>, where('public', '==', true)
            // )
          )
        })
      )
      .subscribe((actions: DocumentChange<App>[]) => {
        if (!actions) {
          return
        } else {
        actions.forEach(action => {
          const app = convertDocumentSnapshotToEntity<App>(action.doc);
          switch (action.type) {
            case 'added':
              return this.store$.dispatch(new CreateAppSuccessAction({app}));
            case 'modified':
              return this.store$.dispatch(new UpdateAppSuccessAction({app}));
            case 'removed':
              return this.store$.dispatch(new DeleteAppSuccessAction({appId: app.id}));
          }
        });
      }
    });
  }

  public ngOnDestroy() {
    console.log('SURVEYS SYNC DESTROY')
    if (this.subscription) {
      console.log('app sync subscription destroy')
      this.subscription.unsubscribe();
    }
  }
  
}