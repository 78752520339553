import {Action} from '@ngrx/store';
import {Survey} from '../../../models/survey';

export enum SurveysActionType {
  GET_SUCCESS = '[Surveys] Get :: Success',

  CREATE = '[Surveys] Create On Server',
  CREATE_SUCCESS = '[Surveys] Add To Store',

  UPDATE = '[Surveys] Update On Server',
  UPDATE_SUCCESS = '[Surveys] Modify In Store',

  DELETE = '[Surveys] Delete On Server',
  DELETE_SUCCESS = '[Surveys] Remove From Store',

  SET_SYNCED = '[Surveys] Set Synced',
  SET_LOADED = '[Surveys] Set Loaded',

  CLEAR = '[Surveys] Clear',
}

export class GetSurveysSuccessAction implements Action {
  public readonly type = SurveysActionType.GET_SUCCESS;

  public constructor(public payload: {surveys: Survey[]}) {}
}

export class CreateSurveyAction implements Action {
  public readonly type = SurveysActionType.CREATE;

  public constructor(
    public payload: {
      survey: Partial<Survey>;
      onSuccess?: (survey: Survey) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class CreateSurveySuccessAction implements Action {
  public readonly type = SurveysActionType.CREATE_SUCCESS;

  public constructor(public payload: {survey: Survey}) {}
}

export class UpdateSurveyAction implements Action {
  public readonly type = SurveysActionType.UPDATE;

  public constructor(
    public payload: {
      surveyId: string;
      survey: Partial<Survey>;
      onSuccess?: (survey: Survey) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdateSurveySuccessAction implements Action {
  public readonly type = SurveysActionType.UPDATE_SUCCESS;

  public constructor(public payload: {survey: Survey}) {}
}

export class DeleteSurveyAction implements Action {
  public readonly type = SurveysActionType.DELETE;

  public constructor(
    public payload: {
      surveyId: string;
      onSuccess?: (surveyId: string) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class DeleteSurveySuccessAction implements Action {
  public readonly type = SurveysActionType.DELETE_SUCCESS;

  public constructor(public payload: {surveyId: string}) {}
}

export class SetSurveysSyncedAction implements Action {
  public readonly type = SurveysActionType.SET_SYNCED;

  public constructor(public payload: {synced: boolean}) {}
}

export class ClearSurveysAction implements Action {
  public readonly type = SurveysActionType.CLEAR;
}

export type SurveysAction =
  | GetSurveysSuccessAction
  | CreateSurveyAction
  | CreateSurveySuccessAction
  | UpdateSurveyAction
  | UpdateSurveySuccessAction
  | DeleteSurveyAction
  | DeleteSurveySuccessAction
  | SetSurveysSyncedAction
  | ClearSurveysAction;
