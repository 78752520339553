import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {UtilitiesService} from '../../firestore/utilities.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {CreateUtilityAction, DeleteUtilityAction, UtilitiesActionType, UpdateUtilityAction} from './utilities.action';

@Injectable()
export class UtilitiesEffects {

  public create$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CreateUtilityAction>(UtilitiesActionType.CREATE),
      mergeMap(action => {
        const {utility, onSuccess, onFailure} = action.payload;
        return this.utilitiesService.create(utility).pipe(
          mergeMap(createdUtility => createCallbackActions(onSuccess, createdUtility)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )}
  );

  public update$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<UpdateUtilityAction>(UtilitiesActionType.UPDATE),
      mergeMap(action => {
        const {utilityId, utility, onSuccess, onFailure} = action.payload;

        return this.utilitiesService.update(utilityId, utility).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  })

  public delete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeleteUtilityAction>(UtilitiesActionType.DELETE),
      mergeMap(action => {
        const {utilityId, onSuccess, onFailure} = action.payload;
        return this.utilitiesService.delete(utilityId).pipe(
          mergeMap(() => createCallbackActions(onSuccess, utilityId)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  });

  constructor(private actions$: Actions, private utilitiesService: UtilitiesService) {}
}
