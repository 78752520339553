import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {from, Observable} from 'rxjs';
import {catchError, filter, map, mergeMap, take} from 'rxjs/operators';
import {PeersService} from '../../firestore/peers.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  PeersActionType,
  CreatePeersAction,
  DeletePeerAction,
  UpdatePeerAction,
} from './peers.action';
import {selectPeerById} from './peers.selector';

@Injectable()
export class PeersEffects {

  public create$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CreatePeersAction>(PeersActionType.CREATE),
      mergeMap(action => {
        const {projectId, peers, onSuccess, onFailure} = action.payload;
        return from(
          // peers.length === 1 ?
            this.peersService.create(projectId, peers[0])
            // : this.peersService.createMany(projectId, peers)
        ).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public update$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<UpdatePeerAction>(PeersActionType.UPDATE),
      mergeMap(action => {
        const {projectId, peerId, peer, onSuccess, onFailure} = action.payload;

        return this.peersService.update(projectId, peerId, peer).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  });

  public delete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeletePeerAction>(PeersActionType.DELETE),
      mergeMap(action => {
        const {projectId, peerId, onSuccess, onFailure} = action.payload;
        return this.peersService.delete(projectId, peerId).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  constructor(private actions$: Actions, private peersService: PeersService) {}
}
