import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Survey} from '../../../models/survey';

export interface SurveysState extends EntityState<Survey> {
  synced: boolean;
}

export const surveyAdapter = createEntityAdapter<Survey>({
  sortComparer: (a, b) =>
    a.createTime < b.createTime ? 1: -1
});

export const initialSurveysState: SurveysState = surveyAdapter.getInitialState({
  synced: false,
});