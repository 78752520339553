import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectAppIdRouterParam } from '../router/router.selector';
import { appAdapter } from './app.state';

export const selectAppsState = (state: AppState) => state.apps;

export const selectAllApps = createSelector(
  selectAppsState,
  appAdapter.getSelectors().selectAll
);

export const selectAppsMap = createSelector(
  selectAppsState,
  appAdapter.getSelectors().selectEntities
);

export const selectAppById = (appId: string) =>
  createSelector(
    selectAppsMap,
    appsMap => appsMap[appId]
  );

export const selectCurrentApp = createSelector(
  selectAppsMap,
  selectAppIdRouterParam,
  (appsMap, appId) => appsMap[appId]
);

export const selectAppsSynced = createSelector(
  selectAppsState,
  state => state.synced
);
