import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { WorkspaceResolver } from './core/resolvers/workspace.resolver';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {CustomRouterStateSerializer} from './core/store/router/router.state';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule),
  },
  {
    path: 'join',
    loadChildren: () => import('./join/join.module').then( m => m.JoinPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'workspace',
    loadChildren: () => import('./workspace/workspace.module').then( m => m.WorkspacePageModule),
    canActivate: [AuthGuard],
    resolve: {init: WorkspaceResolver},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  // {
  //   path: 'platform',
  //   loadChildren: () => import('./platform/platform.module').then( m => m.PlatformPageModule),
  //   canActivate: [AuthGuard],
  //   resolve: {init: WorkspaceResolver},
  //   runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  // },
  {
    path: 'survey',
    loadChildren: () => import('./survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    useHash: true,
  }), StoreRouterConnectingModule.forRoot()],
  exports: [RouterModule],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
})
export class AppRoutingModule { }
