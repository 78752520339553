import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Project} from '../../../models/project';

export interface ProjectsState extends EntityState<Project> {
  synced: boolean;
  loaded: boolean;
}

export const projectAdapter = createEntityAdapter<Project>({
  sortComparer: (a, b) =>
    a.createTime < b.createTime ? 1: -1
});

export const initialProjectsState: ProjectsState = projectAdapter.getInitialState({
  synced: false,
  loaded: false,
});