import {Action} from '@ngrx/store';
import {Utility} from '../../../models/utility';

export enum UtilitiesActionType {
  GET_SUCCESS = '[Utilities] Get :: Success',

  CREATE = '[Utilities] Create On Server',
  CREATE_SUCCESS = '[Utilities] Add To Store',

  UPDATE = '[Utilities] Update On Server',
  UPDATE_SUCCESS = '[Utilities] Modify In Store',

  DELETE = '[Utilities] Delete On Server',
  DELETE_SUCCESS = '[Utilities] Remove From Store',

  SET_SYNCED = '[Utilities] Set Synced',
  SET_LOADED = '[Utilities] Set Loaded',

  CLEAR = '[Utilities] Clear',
}

export class GetUtilitiesSuccessAction implements Action {
  public readonly type = UtilitiesActionType.GET_SUCCESS;

  public constructor(public payload: {utilities: Utility[]}) {}
}

export class CreateUtilityAction implements Action {
  public readonly type = UtilitiesActionType.CREATE;

  public constructor(
    public payload: {
      utility: Partial<Utility>;
      onSuccess?: (utility: Utility) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class CreateUtilitySuccessAction implements Action {
  public readonly type = UtilitiesActionType.CREATE_SUCCESS;

  public constructor(public payload: {utility: Utility}) {}
}

export class UpdateUtilityAction implements Action {
  public readonly type = UtilitiesActionType.UPDATE;

  public constructor(
    public payload: {
      utilityId: string;
      utility: Partial<Utility>;
      onSuccess?: (utility: Utility) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdateUtilitySuccessAction implements Action {
  public readonly type = UtilitiesActionType.UPDATE_SUCCESS;

  public constructor(public payload: {utility: Utility}) {}
}

export class DeleteUtilityAction implements Action {
  public readonly type = UtilitiesActionType.DELETE;

  public constructor(
    public payload: {
      utilityId: string;
      onSuccess?: (utilityId: string) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class DeleteUtilitySuccessAction implements Action {
  public readonly type = UtilitiesActionType.DELETE_SUCCESS;

  public constructor(public payload: {utilityId: string}) {}
}

export class SetUtilitiesSyncedAction implements Action {
  public readonly type = UtilitiesActionType.SET_SYNCED;

  public constructor(public payload: {synced: boolean}) {}
}

export class SetUtilitiesLoadedAction implements Action {
  public readonly type = UtilitiesActionType.SET_LOADED;

  public constructor(public payload: {loaded: boolean}) {}
}

export class ClearUtilitiesAction implements Action {
  public readonly type = UtilitiesActionType.CLEAR;
}

export type UtilitiesAction =
  | GetUtilitiesSuccessAction
  | CreateUtilityAction
  | CreateUtilitySuccessAction
  | UpdateUtilityAction
  | UpdateUtilitySuccessAction
  | DeleteUtilityAction
  | DeleteUtilitySuccessAction
  | SetUtilitiesSyncedAction
  | SetUtilitiesLoadedAction
  | ClearUtilitiesAction;
