import {Data, Params, RouterStateSnapshot} from '@angular/router';
import {RouterReducerState, RouterStateSerializer} from '@ngrx/router-store';

export interface CustomRouterReducerState extends RouterReducerState<CustomRouterState> {
  previousState?: CustomRouterState;
}

export interface CustomRouterState {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

export class CustomRouterStateSerializer implements RouterStateSerializer<CustomRouterState> {
  public serialize(routerState: RouterStateSnapshot): CustomRouterState {
    let route = routerState.root;

    let data: Data = {};
    const params: Params = {};
    const queryParams: Params = {};

    while (route) {
      data = {...data, ...route.data};
      for (const param of route.paramMap.keys) {
        params[param] = route.paramMap.get(param);
      }
      for (const queryParam of route.queryParamMap.keys) {
        queryParams[queryParam] = route.queryParamMap.get(queryParam);
      }
      route = route.firstChild;
    }

    const {url} = routerState;

    return {url, params, queryParams, data};
  }
}