import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Suggestion} from '../../../models/suggestion';

export interface SuggestionsState extends EntityState<Suggestion> {
  synced: boolean;
}

export const suggestionAdapter = createEntityAdapter<Suggestion>({
  sortComparer: (a, b) =>
    a.createTime < b.createTime ? 1: -1
});

export const initialSuggestionsState: SuggestionsState = suggestionAdapter.getInitialState({
  synced: false,
});