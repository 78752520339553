import {UtilitiesAction, UtilitiesActionType} from './utilities.action';
import {initialUtilitiesState, utilitiesAdapter, UtilitiesState} from './utilities.state';

export function utilitiesReducer(state: UtilitiesState = initialUtilitiesState, action: UtilitiesAction): UtilitiesState {
  switch (action.type) {
    case UtilitiesActionType.GET_SUCCESS:
      return utilitiesAdapter.upsertMany(action.payload.utilities, state);
    case UtilitiesActionType.CREATE_SUCCESS:
      return utilitiesAdapter.addOne(action.payload.utility, state);
    case UtilitiesActionType.UPDATE_SUCCESS:
      return utilitiesAdapter.upsertOne(action.payload.utility, state);
    case UtilitiesActionType.DELETE_SUCCESS:
      return utilitiesAdapter.removeOne(action.payload.utilityId, state);
    case UtilitiesActionType.SET_SYNCED:
      return {...state, synced: action.payload.synced};
    case UtilitiesActionType.SET_LOADED:
      return {...state, loaded: action.payload.loaded};
    case UtilitiesActionType.CLEAR:
      return initialUtilitiesState;
    default:
      return state;
  }
}
