import { SetUtilitiesSyncedAction } from './../core/store/utilities/utilities.action';
import { UtilitiesSyncService } from './../core/firestore/sync/utilities-sync.service';
import { selectPreviousRouterUrl } from './../core/store/router/router.selector';
import { SetProjectsSyncedAction } from './../core/store/project/project.action';
import { SetAppsSyncedAction } from './../core/store/app/app.action';
import { SetSuggestionsSyncedAction } from './../core/store/suggestion/suggestion.action';
import { SetUsersSyncedAction } from './../core/store/users/users.action';
import { Store, select } from '@ngrx/store';
import { AppsSyncService } from './../core/firestore/sync/app-sync.service';
import { SuggestionsSyncService } from './../core/firestore/sync/suggestions-sync.service';
import { ProjectsSyncService } from './../core/firestore/sync/projects-sync.service';
import { UsersSyncService } from './../core/firestore/sync/users-sync.service';
import { ViewService } from './../core/page/view.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router, ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: Auth,
    private store$: Store<{}>,
    private router: Router,
    private utilitiesSyncService: UtilitiesSyncService,
    private usersSyncService: UsersSyncService,
    private projectsSyncService: ProjectsSyncService,
    private suggestionsSyncService: SuggestionsSyncService,
    private appsSyncService: AppsSyncService,
    public viewService: ViewService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {

    this.viewService.changeDataLoading(true);

    this.utilitiesSyncService.init();
    this.usersSyncService.init();
    this.projectsSyncService.init();
    this.suggestionsSyncService.init();
    this.appsSyncService.init();

    this.store$.dispatch(new SetUtilitiesSyncedAction({synced: true}));
    this.store$.dispatch(new SetUsersSyncedAction({synced: true}));
    this.store$.dispatch(new SetProjectsSyncedAction({synced: true}));
    this.store$.dispatch(new SetSuggestionsSyncedAction({synced: true}));
    this.store$.dispatch(new SetAppsSyncedAction({synced: true}));

    return new Promise((resolve, reject) => {
      // console.log('router:', this.router);
      // console.log('next:', next);
      // console.log('state:', state);
      onAuthStateChanged(this.auth, user => {
        if (user) {
          // if (state.url?.includes('project-create')) {
          //   this.router.navigate(['/workspace/project-create'], { replaceUrl: true });
          // }
          resolve(true);
        } else {
          this.router.navigate(['/auth'], { replaceUrl: true });
          this.viewService.changeDataLoading(false);
          resolve(false);
        }
      });
    })
  }
     
}
