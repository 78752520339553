import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Peer} from '../../models/peer';
import { Firestore } from '@angular/fire/firestore';
import { doc, addDoc, updateDoc, deleteDoc, CollectionReference, collection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})

export class PeersService {

  constructor(
    private firestore: Firestore
  ) {}

  public create(projectId: string, peer: Partial<Peer>): Observable<Peer> {
    const createTime = new Date();
    const peerEntity = {...peer, createTime};
    return from (
      addDoc(this.collectionReference(projectId), peerEntity)
    ).pipe(map(() => ({...peerEntity} as Peer)))
  }   
  
  // public createMany(projectId: string, peers: Partial<Peer>[]): Observable<void> {
  //   return from(
  //     Promise.all(
  //       splitArrayIntoChunks(peers, 500).map(chunkTeamMembers =>
  //         this.angularFirestore.firestore.runTransaction(transaction =>
  //           Promise.all(
  //             chunkTeamMembers.map(teamMember => {
  //               const teamMemberId = this.angularFirestore.createId();
  //               const document = this.collection(projectId).doc(teamMemberId);
  //               transaction.set(document.ref, teamMember);
  //             })
  //           )
  //         )
  //       )
  //     ).then(() => {})
  //   );
  // }

  public update(projectId: string, peerId: string, peer: Partial<Peer>): Observable<void> {
    return from(
      updateDoc(doc(this.firestore, `projects/${projectId}/peers`, peerId), peer)
    );
  }

  public delete(projectId: string, peerId: string): Observable<void> {
    return from(
      deleteDoc(doc(this.collectionReference(projectId), peerId))
    );
  }

  public collectionReference(projectId: string) {
    return collection(this.firestore, `projects/${projectId}/peers`) as CollectionReference<Peer>;
  }

}