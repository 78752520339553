import {AppsAction, AppsActionType} from './app.action';
import {initialAppsState, appAdapter, AppsState} from './app.state';

export function appsReducer(state: AppsState = initialAppsState, action: AppsAction): AppsState {
  switch (action.type) {
    case AppsActionType.GET_SUCCESS:
      return appAdapter.upsertMany(action.payload.apps, state);
    case AppsActionType.CREATE_SUCCESS:
      return appAdapter.addOne(action.payload.app, state);
    case AppsActionType.UPDATE_SUCCESS:
      return appAdapter.upsertOne(action.payload.app, state);
    case AppsActionType.DELETE_SUCCESS:
      return appAdapter.removeOne(action.payload.appId, state);
    case AppsActionType.SET_SYNCED:
      return {...state, synced: action.payload.synced};
    case AppsActionType.CLEAR:
      return initialAppsState;
    default:
      return state;
  }
}
