import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';
import {usersAdapter} from './users.state';

export const selectUsersState = (state: AppState) => state.users;

export const selectCurrentUserId = createSelector(
  selectUsersState,
  state => state.currentUserId
);

// Inactivate? --> User sync service is limited to same uid only => always only one user in store
export const selectAllUsers = createSelector(
  selectUsersState,
  usersAdapter.getSelectors().selectAll
);

// Temporary
export const selectCurrentUser = createSelector(
  selectAllUsers,
  users => users[0]
);

export const selectUsersMap = createSelector(
  selectUsersState,
  usersAdapter.getSelectors().selectEntities
);

export const selectUserById = (userId: string) =>
  createSelector(
    selectUsersMap,
    usersMap => usersMap[userId]
);

export const selectUsersSynced = createSelector(
  selectUsersState,
  state => state.synced
);

export const selectUsersLoaded = createSelector(
  selectUsersState,
  state => state.loaded
);

export const selectUserByEmail = (userEmail: string) =>
  createSelector(
    selectAllUsers,
    users => users.filter(user => user.email === userEmail)[0]
);