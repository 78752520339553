import { SetCurrentUserAction } from '../../store/users/users.action';
import { User } from './../../../models/users';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import {
  Auth,
  onAuthStateChanged
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})

export class AuthSyncService implements OnDestroy {

  private subscription: Subscription;
  public user$: Observable<User>;

  constructor(
    private auth: Auth,
    private store$: Store<{}>,
  ) {}

  public init() {
    this.subscribeToUsersChanges();
  }

  private subscribeToUsersChanges() {
    return onAuthStateChanged(this.auth, user => {
      if (user) {
        this.store$.dispatch(new SetCurrentUserAction({currentUserId: user.uid}));
      }
    });
  }

  // relavant?
  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}