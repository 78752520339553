import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Firestore } from '@angular/fire/firestore';
import { doc, addDoc, collection, updateDoc, deleteDoc, CollectionReference } from '@angular/fire/firestore';
import { User } from '../../models/users';

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  constructor(
    private firestore: Firestore
  ) {}

  public create(user: Partial<User>): Observable<User> {
    const createTime = new Date();
    const userEntity = {...user, createTime};
    return from (
      addDoc(this.collectionReference(), userEntity)
    ).pipe(map(() => ({...userEntity} as User)))
  }                                                                                                                          

  public update(userId: string, user: Partial<User>): Observable<void> {
    return from(
      updateDoc(doc(this.firestore, 'users', userId), user)
    );
  }

  public delete(userId: string): Observable<void> {
    return from(
      deleteDoc(doc(this.collectionReference(), userId))
    );
  }

  public collectionReference() {
    return collection(this.firestore, 'users') as CollectionReference<User>;
  }
}