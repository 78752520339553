import {Action} from '@ngrx/store';
import {Suggestion} from '../../../models/suggestion';

export enum SuggestionsActionType {
  GET_SUCCESS = '[Suggestions] Get :: Success',

  CREATE = '[Suggestions] Create On Server',
  CREATE_SUCCESS = '[Suggestions] Add To Store',

  UPDATE = '[Suggestions] Update On Server',
  UPDATE_SUCCESS = '[Suggestions] Modify In Store',

  DELETE = '[Suggestions] Delete On Server',
  DELETE_SUCCESS = '[Suggestions] Remove From Store',

  SET_SYNCED = '[Suggestions] Set Synced',
  SET_LOADED = '[Suggestions] Set Loaded',

  CLEAR = '[Suggestions] Clear',
}

export class GetSuggestionsSuccessAction implements Action {
  public readonly type = SuggestionsActionType.GET_SUCCESS;

  public constructor(public payload: {suggestions: Suggestion[]}) {}
}

export class CreateSuggestionAction implements Action {
  public readonly type = SuggestionsActionType.CREATE;

  public constructor(
    public payload: {
      suggestion: Partial<Suggestion>;
      onSuccess?: (suggestion: Suggestion) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class CreateSuggestionSuccessAction implements Action {
  public readonly type = SuggestionsActionType.CREATE_SUCCESS;

  public constructor(public payload: {suggestion: Suggestion}) {}
}

export class UpdateSuggestionAction implements Action {
  public readonly type = SuggestionsActionType.UPDATE;

  public constructor(
    public payload: {
      suggestionId: string;
      suggestion: Partial<Suggestion>;
      onSuccess?: (suggestion: Suggestion) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdateSuggestionSuccessAction implements Action {
  public readonly type = SuggestionsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {suggestion: Suggestion}) {}
}

export class DeleteSuggestionAction implements Action {
  public readonly type = SuggestionsActionType.DELETE;

  public constructor(
    public payload: {
      suggestionId: string;
      onSuccess?: (suggestionId: string) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class DeleteSuggestionSuccessAction implements Action {
  public readonly type = SuggestionsActionType.DELETE_SUCCESS;

  public constructor(public payload: {suggestionId: string}) {}
}

export class SetSuggestionsSyncedAction implements Action {
  public readonly type = SuggestionsActionType.SET_SYNCED;

  public constructor(public payload: {synced: boolean}) {}
}

export class ClearSuggestionsAction implements Action {
  public readonly type = SuggestionsActionType.CLEAR;
}

export type SuggestionsAction =
  | GetSuggestionsSuccessAction
  | CreateSuggestionAction
  | CreateSuggestionSuccessAction
  | UpdateSuggestionAction
  | UpdateSuggestionSuccessAction
  | DeleteSuggestionAction
  | DeleteSuggestionSuccessAction
  | SetSuggestionsSyncedAction
  | ClearSuggestionsAction;
