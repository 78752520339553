import {ProjectsAction, ProjectsActionType} from './project.action';
import {initialProjectsState, projectAdapter, ProjectsState} from './project.state';

export function projectsReducer(state: ProjectsState = initialProjectsState, action: ProjectsAction): ProjectsState {
  switch (action.type) {
    case ProjectsActionType.GET_SUCCESS:
      return projectAdapter.upsertMany(action.payload.projects, state);
    case ProjectsActionType.CREATE_SUCCESS:
      return projectAdapter.addOne(action.payload.project, state);
    case ProjectsActionType.UPDATE_SUCCESS:
      return projectAdapter.upsertOne(action.payload.project, state);
    case ProjectsActionType.DELETE_SUCCESS:
      return projectAdapter.removeOne(action.payload.projectId, state);
    case ProjectsActionType.SET_SYNCED:
      return {...state, synced: action.payload.synced};
    case ProjectsActionType.SET_LOADED:
      return {...state, loaded: action.payload.loaded};
    case ProjectsActionType.CLEAR:
      return initialProjectsState;
    default:
      return state;
  }
}
