import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { ViewService } from 'src/app/core/page/view.service';
import { User } from 'src/app/models/users';
import { select, Store } from '@ngrx/store';
import { MediaMatcher } from '@angular/cdk/layout';
import { Capacitor } from '@capacitor/core';
import { Auth } from '@angular/fire/auth';
import { Logout } from '../../../../core/store/logout/logout.action';
import { selectCurrentUser } from 'src/app/core/store/users/users.selector';
import { UserSettingsOverviewPage } from '../user-settings-overview/user-settings-overview.page';
import { selectUtilityByTag } from 'src/app/core/store/utilities/utilities.selector';
import { UserSettingsProjectsPage } from '../user-settings-projects/user-settings-projects.page';
import { SuggestionPage } from '../../../utilities/suggestion/suggestion.page';

@Component({
  selector: 'app-user-settings-menu',
  templateUrl: './user-settings-menu.page.html',
  styleUrls: ['./user-settings-menu.page.scss'],
})
export class UserSettingsMenuPage implements OnInit {

  public currentUser$: Observable<User>;
  public native: boolean = false;
  public appMenuIndex: number = 0;
  public collapse: boolean;
  public subscriptions = new Subscription();
  public nav: any = null;
  public userSettingsNav$: Observable<any>;
  public utilityNav$: Observable<any>;

  public mobileQuery: MediaQueryList;
  public gridQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private store$: Store<{}>,
    private auth: Auth,
    private modalController: ModalController,
    public ref: ChangeDetectorRef,
    public media: MediaMatcher,
    public viewService: ViewService,
  ) { 
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => ref.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (Capacitor.isNativePlatform() || this.mobileQuery.matches) {
      this.native = true
    }
  }

  ngOnInit() {
    this.currentUser$ = this.store$.pipe(select(selectCurrentUser));
    this.userSettingsNav$ = this.store$.pipe(select(selectUtilityByTag('user-settings')));
    this.utilityNav$ = this.store$.pipe(select(selectUtilityByTag('utility-nav')));
    this.subscriptions.add(this.subscribeToViewService())
  }

  public ngAfterViewInit() {
    this.nav = document.querySelector('ion-nav');
  }

  public subscribeToViewService(): Subscription {
    return this.viewService.userSettingsCollapse$.subscribe(view => {
      this.collapse = view
    })
  }

  public navigateNext(tag, i) {

    let pageObject;

    switch(tag) {
      case 'general':
        pageObject = UserSettingsOverviewPage;
        break;
      // case 'UserSettingsNotificationsPage':
      //   pageObject = UserSettingsNotificationsPage;
      //   break;
      case 'projects':
        pageObject = UserSettingsProjectsPage;
        break;
      // case 'UserSettingsPrivacyPage':
      //   pageObject = UserSettingsPrivacyPage;
      //   break;
      // case 'UserSettingsChangeLogPage':
      //   pageObject = UserSettingsChangeLogPage;
      //   break;
      // case 'BugReportPage':
      //   pageObject = BugReportPage;
      //   break;
      case 'feature-suggestion':
        pageObject = SuggestionPage;
        break;
      // case 'FeedbackPage':
      //   pageObject = FeedbackPage;
      //   break;
    }
    this.appMenuIndex = i;
    this.nav.push(pageObject);
  }


  public toggleCollapse() {
    this.collapse = !this.collapse
    this.viewService.changeUserSettingsCollapse(this.collapse);
  }

  public dismiss() {
    this.modalController.dismiss(null, 'cancel');
  }

  public logout() {
    this.dismiss();
    this.auth.signOut();
    // this.store$.dispatch(new Logout())
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

}