import {PeersAction, PeersActionType} from './peers.action';
import {peersAdapter, PeersState, initialPeersState} from './peers.state';

export function peersReducer(
  state: PeersState = initialPeersState,
  action: PeersAction
): PeersState {
  switch (action.type) {
    case PeersActionType.ADD:
      return peersAdapter.addOne(action.payload.peer, state);
    case PeersActionType.MODIFY:
      return peersAdapter.upsertOne(action.payload.peer, state);
    case PeersActionType.REMOVE:
      return peersAdapter.removeOne(action.payload.peerId, state);
    case PeersActionType.SET_LOADED:
      return {...state, loaded: action.payload.loaded};
    case PeersActionType.SET_PROJECT:
      return {...state, projectId: action.payload.projectId};
    case PeersActionType.SET_CURRENT_PEER:
      return {...state, currentPeer: action.payload.currentPeer};
    case PeersActionType.CLEAR:
      return initialPeersState;
    default:
      return state;
  }
}
