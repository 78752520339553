import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {SurveysService} from '../../firestore/survey.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {CreateSurveyAction, DeleteSurveyAction, SurveysActionType, UpdateSurveyAction} from './survey.action';

@Injectable()
export class SurveysEffects {

  public create$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CreateSurveyAction>(SurveysActionType.CREATE),
      mergeMap(action => {
        const {survey, onSuccess, onFailure} = action.payload;
        return this.surveysService.create(survey).pipe(
          mergeMap(createdSurvey => createCallbackActions(onSuccess, createdSurvey)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public update$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<UpdateSurveyAction>(SurveysActionType.UPDATE),
      mergeMap(action => {
        const {surveyId, survey, onSuccess, onFailure} = action.payload;

        return this.surveysService.update(surveyId, survey).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public delete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeleteSurveyAction>(SurveysActionType.DELETE),
      mergeMap(action => {
        const {surveyId, onSuccess, onFailure} = action.payload;
        return this.surveysService.delete(surveyId).pipe(
          mergeMap(() => createCallbackActions(onSuccess, surveyId)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  constructor(private actions$: Actions, private surveysService: SurveysService) {}
}
