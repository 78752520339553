import {Action} from '@ngrx/store';
import {Response} from '../../../models/response';

export enum ResponsesActionType {
  CREATE = '[Responses] Create On Server',
  UPDATE = '[Responses] Update On Server',
  DELETE = '[Responses] Delete On Server',

  ADD = '[Responses] Add To Store',
  MODIFY = '[Responses] Modify In Store',
  REMOVE = '[Responses] Remove From Store',

  SET_LOADED = '[Responses] Set Loaded',
  SET_SURVEY = '[Responses] Set Survey',

  CLEAR = '[Responses] Clear',
}

export class CreateResponsesAction implements Action {
  public readonly type = ResponsesActionType.CREATE;

  public constructor(
    public payload: {
      surveyId: string;
      responses: Partial<Response>[];
      onSuccess?: () => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class AddResponseAction implements Action {
  public readonly type = ResponsesActionType.ADD;

  public constructor(public payload: {response: Response}) {}
}

export class ModifyResponseAction implements Action {
  public readonly type = ResponsesActionType.MODIFY;

  public constructor(public payload: {response: Response}) {}
}

export class RemoveResponseAction implements Action {
  public readonly type = ResponsesActionType.REMOVE;

  public constructor(public payload: {responseId: string}) {}
}

export class DeleteResponseAction implements Action {
  public readonly type = ResponsesActionType.DELETE;

  public constructor(
    public payload: {
      surveyId: string;
      responseId: string;
      onSuccess?: () => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdateResponseAction implements Action {
  public readonly type = ResponsesActionType.UPDATE;

  public constructor(
    public payload: {
      surveyId: string;
      responseId: string;
      response: Partial<Response>;
      onSuccess?: () => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class SetResponsesLoadedAction implements Action {
  public readonly type = ResponsesActionType.SET_LOADED;

  public constructor(public payload: {loaded: boolean}) {}
}

export class SetResponsesSurveyAction implements Action {
  public readonly type = ResponsesActionType.SET_SURVEY;

  public constructor(public payload: {surveyId: string}) {}
}

export class ClearResponsesAction implements Action {
  public readonly type = ResponsesActionType.CLEAR;
}

export type ResponsesAction =
  | CreateResponsesAction
  | UpdateResponseAction
  | DeleteResponseAction
  | AddResponseAction
  | ModifyResponseAction
  | RemoveResponseAction
  | SetResponsesLoadedAction
  | SetResponsesSurveyAction
  | ClearResponsesAction;
