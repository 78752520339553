import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {App} from '../../models/app';
import { Firestore } from '@angular/fire/firestore';
import { doc, addDoc, collection, updateDoc, deleteDoc, CollectionReference } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})

export class AppsService {

  constructor(
    private firestore: Firestore
  ) {}

  public create(app: Partial<App>): Observable<App> {
    const createTime = new Date();
    const appApp = {...app, createTime};
    return from (
      addDoc(this.collectionReference(), appApp)
    ).pipe(map(() => ({...appApp} as App)))
  }                                                                                                                          

  public update(appId: string, app: Partial<App>): Observable<void> {
    return from(
      updateDoc(doc(this.firestore, 'apps', appId), app)
    );
  }

  public delete(appId: string): Observable<void> {
    return from(
      deleteDoc(doc(this.collectionReference(), appId))
    );
  }

  public collectionReference() {
    return collection(this.firestore, 'apps') as CollectionReference<App>;
  }

}