import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {User} from '../../../models/users';

export interface UsersState extends EntityState<User> {
  synced: boolean;
  loaded: boolean;
  currentUserId: string;
}

export const usersAdapter = createEntityAdapter<User>({
  sortComparer: (a, b) =>
    a.email.localeCompare(b.email)
});

export const initialUsersState: UsersState = usersAdapter.getInitialState({
  synced: false,
  loaded: false,
  currentUserId: null,
});