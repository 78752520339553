import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Suggestion} from '../../models/suggestion';
import { Firestore } from '@angular/fire/firestore';
import { doc, addDoc, collection, updateDoc, deleteDoc, CollectionReference } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})

export class SuggestionsService {

  constructor(
    private firestore: Firestore
  ) {}

  public create(suggestion: Partial<Suggestion>): Observable<Suggestion> {
    const createTime = new Date();
    const suggestionSuggestion = {...suggestion, createTime};
    return from (
      addDoc(this.collectionReference(), suggestionSuggestion)
    ).pipe(map(() => ({...suggestionSuggestion} as Suggestion)))
  }                                                                                                                          

  public update(suggestionId: string, suggestion: Partial<Suggestion>): Observable<void> {
    return from(
      updateDoc(doc(this.firestore, 'suggestions', suggestionId), suggestion)
    );
  }

  public delete(suggestionId: string): Observable<void> {
    return from(
      deleteDoc(doc(this.collectionReference(), suggestionId))
    );
  }

  public collectionReference() {
    return collection(this.firestore, 'suggestions') as CollectionReference<Suggestion>;
  }

}