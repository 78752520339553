import {Action} from '@ngrx/store';
import {Peer} from '../../../models/peer';

export enum PeersActionType {
  CREATE = '[Peers] Create On Server',
  UPDATE = '[Peers] Update On Server',
  DELETE = '[Peers] Delete On Server',

  ADD = '[Peers] Add To Store',
  MODIFY = '[Peers] Modify In Store',
  REMOVE = '[Peers] Remove From Store',

  SET_LOADED = '[Peers] Set Loaded',
  SET_PROJECT = '[Peers] Set Project',
  SET_CURRENT_PEER = '[Peers] Set Current Peer',

  CLEAR = '[Peers] Clear',
}

export class SetCurrentPeerAction implements Action {
  public readonly type = PeersActionType.SET_CURRENT_PEER;

  public constructor(public payload: {currentPeer: Peer}) {}
}

export class CreatePeersAction implements Action {
  public readonly type = PeersActionType.CREATE;

  public constructor(
    public payload: {
      projectId: string;
      peers: Partial<Peer>[];
      onSuccess?: () => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class AddPeerAction implements Action {
  public readonly type = PeersActionType.ADD;

  public constructor(public payload: {peer: Peer}) {}
}

export class ModifyPeerAction implements Action {
  public readonly type = PeersActionType.MODIFY;

  public constructor(public payload: {peer: Peer}) {}
}

export class RemovePeerAction implements Action {
  public readonly type = PeersActionType.REMOVE;

  public constructor(public payload: {peerId: string}) {}
}

export class DeletePeerAction implements Action {
  public readonly type = PeersActionType.DELETE;

  public constructor(
    public payload: {
      projectId: string;
      peerId: string;
      onSuccess?: () => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdatePeerAction implements Action {
  public readonly type = PeersActionType.UPDATE;

  public constructor(
    public payload: {
      projectId: string;
      peerId: string;
      peer: Partial<Peer>;
      onSuccess?: () => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class SetPeersLoadedAction implements Action {
  public readonly type = PeersActionType.SET_LOADED;

  public constructor(public payload: {loaded: boolean}) {}
}

export class SetPeersProjectAction implements Action {
  public readonly type = PeersActionType.SET_PROJECT;

  public constructor(public payload: {projectId: string}) {}
}

export class ClearPeersAction implements Action {
  public readonly type = PeersActionType.CLEAR;
}

export type PeersAction =
  | SetCurrentPeerAction
  | CreatePeersAction
  | UpdatePeerAction
  | DeletePeerAction
  | AddPeerAction
  | ModifyPeerAction
  | RemovePeerAction
  | SetPeersLoadedAction
  | SetPeersProjectAction
  | ClearPeersAction;
