import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectProjectIdRouterParam } from '../router/router.selector';
import { projectAdapter } from './project.state';

export const selectProjectsState = (state: AppState) => state.projects;

export const selectAllProjects = createSelector(
  selectProjectsState,
  projectAdapter.getSelectors().selectAll
);

export const selectProjectsMap = createSelector(
  selectProjectsState,
  projectAdapter.getSelectors().selectEntities
);

export const selectProjectById = (projectId: string) =>
  createSelector(
    selectProjectsMap,
    projectsMap => projectsMap[projectId]
  );

export const selectCurrentProject = createSelector(
  selectProjectsMap,
  selectProjectIdRouterParam,
  (projectsMap, projectId) => projectsMap[projectId]
);

export const selectProjectsSynced = createSelector(
  selectProjectsState,
  state => state.synced
);

export const selectProjectsLoaded = createSelector(
  selectProjectsState,
  state => state.loaded
);
