import { SetUtilitiesLoadedAction } from './../../store/utilities/utilities.action';

import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, of, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Utility} from '../../../models/utility';
import {
  CreateUtilitySuccessAction,
  DeleteUtilitySuccessAction,
  UpdateUtilitySuccessAction,
} from '../../store/utilities/utilities.action';
import {selectUtilitiesSynced} from '../../store/utilities/utilities.selector';
import {convertDocumentSnapshotToEntity} from '../../utils/firebase/convert-document-snapshot-to-entity';
import { Firestore, collectionChanges } from '@angular/fire/firestore';
import { collection, CollectionReference, query, DocumentChange, where } from '@angular/fire/firestore';
import { Auth, user } from '@angular/fire/auth';


// This Services is now injected in the workspace.component.ts in order to fire ngOnDestroy() when leaving the app
@Injectable({
  providedIn: 'root',
})

export class UtilitiesSyncService implements OnDestroy {
  private subscription: Subscription;

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private store$: Store<{}>
  ) {}

  public init() {
    this.subscription = this.subscribeToUtilitiesChanges();
  }

  private subscribeToUtilitiesChanges(): Subscription {
    console.log('UTILITIES SUBSCRIBE')
    return combineLatest([this.store$.pipe(select(selectUtilitiesSynced))])
    // user(this.auth)
      .pipe(
        switchMap(([synced]) => {
          if (!synced) {
            return of([]);
          }
          return collectionChanges<Utility>(
            query<Utility>(
              collection(this.firestore, "utilities") as CollectionReference<Utility>
            )
          )
        })
      )
      .subscribe((actions: DocumentChange<Utility>[]) => {
        if (!actions) {
          return
        } else {
        actions.forEach(action => {
          const utility = convertDocumentSnapshotToEntity<Utility>(action.doc);

          switch (action.type) {
            case 'added':
              return this.store$.dispatch(new CreateUtilitySuccessAction({utility}));
            case 'modified':
              return this.store$.dispatch(new UpdateUtilitySuccessAction({utility}));
            case 'removed':
              return this.store$.dispatch(new DeleteUtilitySuccessAction({utilityId: utility.id}));
          }
        });
        this.store$.dispatch(new SetUtilitiesLoadedAction({loaded: true}));
      }
    });
  }

  public ngOnDestroy() {
    console.log('UTILITIES SYNC DESTROY')
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}