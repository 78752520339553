import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';
import {utilitiesAdapter} from './utilities.state';

export const selectUtilitiesState = (state: AppState) => state.utilities;

export const selectAllUtilities = createSelector(
  selectUtilitiesState,
  utilitiesAdapter.getSelectors().selectAll
);

export const selectUtilitiesMap = createSelector(
  selectUtilitiesState,
  utilitiesAdapter.getSelectors().selectEntities
);

export const selectUtilityByTag = (utilityTag: string) =>
  createSelector(
    selectAllUtilities,
    utilities => utilities.filter(utility => utility.tag === utilityTag)[0]
);

export const selectUtilitiesSynced = createSelector(
  selectUtilitiesState,
  state => state.synced
);

export const selectUtilitiesLoaded = createSelector(
  selectUtilitiesState,
  state => state.loaded
);