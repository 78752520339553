import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';

export const selectCustomRouterState = (state: AppState) => state.router;

export const selectRouterNavigationId = createSelector(
  selectCustomRouterState,
  state => state && state.navigationId
);
export const selectRouterState = createSelector(
  selectCustomRouterState,
  state => state && state.state
);
export const selectPreviousRouterState = createSelector(
  selectCustomRouterState,
  state => state && state.previousState
);

export const selectRouterData = createSelector(
  selectRouterState,
  state => (state && state.data) || {}
);
export const selectRouterDataEntry = (key: string) =>
  createSelector(
    selectRouterData,
    data => data && data[key]
  );
const selectRouterParams = createSelector(
  selectRouterState,
  state => (state && state.params) || {}
);
export const selectRouterParam = (key: string) =>
  createSelector(
    selectRouterParams,
    params => params && params[key]
  );
const selectRouterQueryParams = createSelector(
  selectRouterState,
  state => (state && state.queryParams) || {}
);
export const selectRouterQueryParam = (key: string) =>
  createSelector(
    selectRouterQueryParams,
    queryParams => queryParams && queryParams[key]
  );
export const selectRouterUrl = createSelector(
  selectRouterState,
  state => state && state.url
);

const selectPreviousRouterParams = createSelector(
  selectPreviousRouterState,
  state => (state && state.params) || {}
);
export const selectPreviousRouterParam = (key: string) =>
  createSelector(
    selectPreviousRouterParams,
    params => params && params[key]
  );
export const selectPreviousRouterUrl = createSelector(
  selectPreviousRouterState,
  state => state && state.url
);

// --- Router Parameters Selectors ---

export const selectProjectIdRouterParam = selectRouterParam('projectId');
export const selectFeatureIdRouterParam = selectRouterParam('featureId');
export const selectRetailerIdRouterParam = selectRouterParam('retailerId');
export const selectRegionIdRouterParam = selectRouterParam('regionId');
export const selectArticleIdRouterParam = selectRouterParam('articleId');
export const selectBrandIdRouterParam = selectRouterParam('brandId');
export const selectOrderIdRouterParam = selectRouterParam('orderId');
export const selectWarehouseIdRouterParam = selectRouterParam('warehouseId');
export const selectCollaboratorIdRouterParam = selectRouterParam('collaboratorId');
export const selectCustomerIdRouterParam = selectRouterParam('customerId');
export const selectReceiptIdRouterParam = selectRouterParam('receiptId');
export const selectOrderUnitIdRouterParam = selectRouterParam('orderUnitId');
export const selectPriceGroupIdRouterParam = selectRouterParam('priceGroupId');
export const selectShipmentIdRouterParam = selectRouterParam('shipmentId');
export const selectCustomerActionIdRouterParam = selectRouterParam('customerActionId');
export const selectEntityEmailSettingIdRouterParam = selectRouterParam('entityEmailSettingId');
export const selectFreightlineIdRouterParam = selectRouterParam('freightlineId');
export const selectArrivalIdRouterParam = selectRouterParam('arrivalId');
export const selectParcelIdRouterParam = selectRouterParam('parcelId');
export const selectCardboardIdRouterParam = selectRouterParam('cardboardId');
export const selectTeamMemberIdRouterParam = selectRouterParam('teamMemberId');
export const selectInternalArticleShipmentIdRouterParam = selectRouterParam('internalArticleShipmentId');
export const selectSuggestionIdRouterParam = selectRouterParam('suggestionId');
export const selectSurveyIdRouterParam = selectRouterParam('surveyId');
export const selectAppIdRouterParam = selectRouterParam('appId');
