import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, of, Subscription} from 'rxjs';
import {switchMap } from 'rxjs/operators';
import {Suggestion} from '../../../models/suggestion';
import {
  CreateSuggestionSuccessAction,
  DeleteSuggestionSuccessAction,
  UpdateSuggestionSuccessAction,
} from '../../store/suggestion/suggestion.action';
import {selectSuggestionsSynced} from '../../store/suggestion/suggestion.selector';
import {convertDocumentSnapshotToEntity} from '../../utils/firebase/convert-document-snapshot-to-entity';
import { Firestore } from '@angular/fire/firestore';
import { collectionChanges } from 'rxfire/firestore';
import { collection, CollectionReference, query, where, DocumentChange } from '@angular/fire/firestore';
import { Auth, user } from '@angular/fire/auth';
import { selectCurrentUser } from '../../store/users/users.selector';

@Injectable({
  providedIn: 'root',
})

export class SuggestionsSyncService implements OnDestroy {
  private subscription: Subscription;

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private store$: Store<{}>
  ) {}

  public init() {
    this.subscription = this.subscribeToSuggestionsChanges();
  }

  private subscribeToSuggestionsChanges(): Subscription {
    console.log('SUGGESTIONS SUBSCRIBE')
    return combineLatest([this.store$.pipe(select(selectSuggestionsSynced)), this.store$.select(selectCurrentUser)])
      .pipe(
        switchMap(([synced, user]) => {
          if (!synced || !user) {
            return of([]);
          }
          return collectionChanges<Suggestion>(
            query<Suggestion>(
              collection(this.firestore, "suggestions") as CollectionReference<Suggestion> //, where('createdByUserId', '==', user.id)
            ) 
            // ||
            // query<Suggestion>(
            //   collection(this.firestore, "suggestions") as CollectionReference<Suggestion>, where('public', '==', true)
            // )
          )
        })
      )
      .subscribe((actions: DocumentChange<Suggestion>[]) => {
        if (!actions) {
          return
        } else {
        actions.forEach(action => {
          const suggestion = convertDocumentSnapshotToEntity<Suggestion>(action.doc);
          switch (action.type) {
            case 'added':
              return this.store$.dispatch(new CreateSuggestionSuccessAction({suggestion}));
            case 'modified':
              return this.store$.dispatch(new UpdateSuggestionSuccessAction({suggestion}));
            case 'removed':
              return this.store$.dispatch(new DeleteSuggestionSuccessAction({suggestionId: suggestion.id}));
          }
        });
      }
    });
  }

  public ngOnDestroy() {
    console.log('SUGGESTIONS SYNC DESTROY')
    if (this.subscription) {
      console.log('suggestion sync subscription destroy')
      this.subscription.unsubscribe();
    }
  }
  
}