import {SurveysAction, SurveysActionType} from './survey.action';
import {initialSurveysState, surveyAdapter, SurveysState} from './survey.state';

export function surveysReducer(state: SurveysState = initialSurveysState, action: SurveysAction): SurveysState {
  switch (action.type) {
    case SurveysActionType.GET_SUCCESS:
      return surveyAdapter.upsertMany(action.payload.surveys, state);
    case SurveysActionType.CREATE_SUCCESS:
      return surveyAdapter.addOne(action.payload.survey, state);
    case SurveysActionType.UPDATE_SUCCESS:
      return surveyAdapter.upsertOne(action.payload.survey, state);
    case SurveysActionType.DELETE_SUCCESS:
      return surveyAdapter.removeOne(action.payload.surveyId, state);
    case SurveysActionType.SET_SYNCED:
      return {...state, synced: action.payload.synced};
    case SurveysActionType.CLEAR:
      return initialSurveysState;
    default:
      return state;
  }
}
