import {UsersAction, UsersActionType} from './users.action';
import {initialUsersState, usersAdapter, UsersState} from './users.state';

export function usersReducer(state: UsersState = initialUsersState, action: UsersAction): UsersState {
  switch (action.type) {
    case UsersActionType.GET_SUCCESS:
      return usersAdapter.upsertMany(action.payload.users, state);
    case UsersActionType.CREATE_SUCCESS:
      return usersAdapter.addOne(action.payload.user, state);
    case UsersActionType.UPDATE_SUCCESS:
      return usersAdapter.upsertOne(action.payload.user, state);
    case UsersActionType.DELETE_SUCCESS:
      return usersAdapter.removeOne(action.payload.userId, state);
    case UsersActionType.SET_SYNCED:
      return {...state, synced: action.payload.synced};
    case UsersActionType.SET_LOADED:
      return {...state, loaded: action.payload.loaded};
    case UsersActionType.CLEAR:
      return initialUsersState;
    case UsersActionType.SET_CURRENT_USER:
      return {...state, currentUserId: action.payload.currentUserId};
    default:
      return state;
  }
}
