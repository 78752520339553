import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Response} from '../../../models/response';

export interface ResponsesState extends EntityState<Response> {
  loaded: boolean;
  surveyId: string;
}

export const responsesAdapter = createEntityAdapter<Response>({
  sortComparer: (a, b) => 
    a.createTime < b.createTime ? 1: -1
  // a.title.localeCompare(b.title),
});

export const initialResponsesState: ResponsesState = responsesAdapter.getInitialState({
  loaded: false,
  surveyId: null,
});
