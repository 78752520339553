import { ProjectCreateComponent } from './modals/project-create/project-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer/footer.component';
import { UserSettingsComponent } from './modals/user-settings/user-settings.component';
import { UserSettingsMenuPage } from './modals/user-settings/user-settings-menu/user-settings-menu.page';
import { UserSettingsOverviewPage } from './modals/user-settings/user-settings-overview/user-settings-overview.page';
import { LoadingComponent } from './loading/loading.component';
import { UserSettingsProjectsPage } from './modals/user-settings/user-settings-projects/user-settings-projects.page';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    UserSettingsComponent,
    UserSettingsMenuPage,
    UserSettingsOverviewPage,
    UserSettingsProjectsPage,
    FooterComponent,
    LoadingComponent,
    ProjectCreateComponent
  ],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    IonicModule,
    RouterModule
  ],
  entryComponents: [
    UserSettingsComponent,
    ProjectCreateComponent
  ],
  exports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    IonicModule,
    LoadingComponent,
    // EmptyComponent
    FooterComponent,
    ProjectCreateComponent
  ]
})
export class SharedModule { }
