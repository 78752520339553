import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Firestore } from '@angular/fire/firestore';
import { doc, addDoc, collection, updateDoc, deleteDoc, CollectionReference } from '@angular/fire/firestore';
import { Utility } from '../../models/utility';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {

  constructor(
    private firestore: Firestore
  ) {}

  public create(utility: Partial<Utility>): Observable<Utility> {
    const createTime = new Date();
    const utilityEntity = {...utility, createTime};
    return from (
      addDoc(this.collectionReference(), utilityEntity)
    ).pipe(map(() => ({...utilityEntity} as Utility)))
  }                                                                                                                          

  public update(utilityId: string, utility: Partial<Utility>): Observable<void> {
    return from(
      updateDoc(doc(this.firestore, 'utilities', utilityId), utility)
    );
  }

  public delete(utilityId: string): Observable<void> {
    return from(
      deleteDoc(doc(this.collectionReference(), utilityId))
    );
  }

  public collectionReference() {
    return collection(this.firestore, 'utilities') as CollectionReference<Utility>;
  }
}