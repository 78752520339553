import {ResponsesAction, ResponsesActionType} from './responses.action';
import {responsesAdapter, ResponsesState, initialResponsesState} from './responses.state';

export function responsesReducer(
  state: ResponsesState = initialResponsesState,
  action: ResponsesAction
): ResponsesState {
  switch (action.type) {
    case ResponsesActionType.ADD:
      return responsesAdapter.addOne(action.payload.response, state);
    case ResponsesActionType.MODIFY:
      return responsesAdapter.upsertOne(action.payload.response, state);
    case ResponsesActionType.REMOVE:
      return responsesAdapter.removeOne(action.payload.responseId, state);
    case ResponsesActionType.SET_LOADED:
      return {...state, loaded: action.payload.loaded};
    case ResponsesActionType.SET_SURVEY:
      return {...state, surveyId: action.payload.surveyId};
    case ResponsesActionType.CLEAR:
      return initialResponsesState;
    default:
      return state;
  }
}
