import { selectAllSuggestions } from './../../../core/store/suggestion/suggestion.selector';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { User } from 'src/app/models/users';
import { selectCurrentUser } from 'src/app/core/store/users/users.selector';
import { Capacitor } from '@capacitor/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Suggestion } from 'src/app/models/suggestion';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.page.html',
  styleUrls: ['./suggestion.page.scss'],
})
export class SuggestionPage implements OnInit {

  public currentUser$: Observable<User>;
  public suggestions$: Observable<Suggestion[]>;
  public filteredSuggestions$: Observable<Suggestion[]>;
  public query: string = '';

  public reactions = [
    {
      tag: 'like',
      icon: 'thumbs-up-outline',
    },
    {
      tag: 'dislike',
      icon: 'thumbs-down-outline'
    }
  ]

  public native: boolean = false;
  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private store$: Store<{}>,
    public media: MediaMatcher,
    public ref: ChangeDetectorRef,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => ref.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (Capacitor.isNativePlatform() || this.mobileQuery.matches) {
      this.native = true
    }
  }

  public ngOnInit() {
    this.currentUser$ = this.store$.pipe(select(selectCurrentUser));
    this.suggestions$ = this.store$.select(selectAllSuggestions);

    this.filteredSuggestions$ = combineLatest(this.currentUser$, this.suggestions$)
      .pipe(map(([user, suggestions]) => {
        return suggestions.filter(suggestion => {
          return suggestion.createdByUserId === user?.id
        })
      }))
  }

  public onSearch(user, query) {

    if (query === 'top-voted/10') {
      this.query = 'top-voted/10';
      this.filteredSuggestions$ = combineLatest(this.suggestions$)
      .pipe(map(([suggestions]) => {
        return suggestions.filter(suggestion => suggestion.votes?.length).sort((a, b) => Number(b.votes?.length) - Number(a.votes?.length)).slice(0, 10)
      }))
    } else {
      this.filteredSuggestions$ = combineLatest(this.suggestions$)
      .pipe(map(([suggestions]) => {
        return suggestions.filter(suggestion => {   
          if (query === '') {
            this.query = '';
            return false
          } else {
            let queryArray = query.split(' ');
            switch(query) {
              case 'my-issues':
                this.query = 'my-issues'
                return suggestion.createdByUserId === user.id;
              case 'all-public':
                this.query = 'all-public'
                return suggestion.public;
              default:
                this.query = query;
                return queryArray.some(q => {
                  return suggestion.title.replace(/ /g,'').toLowerCase().includes(q.toLowerCase())
                })
            }
          }
        }).sort((a, b) => a.createTime < b.createTime ? 1: -1)
      }))
    }
  }

  public countReactions(reactions, type) {
    return reactions?.filter(r => r.reaction === type).length || 0
  }
}
