import { BehaviorSubject } from 'rxjs';
import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class ViewService {
  private _viewSource = new BehaviorSubject<boolean>(false);
  private _entitySettingsCollapseSource = new BehaviorSubject<boolean>(false);
  private _userSettingsCollapseSource = new BehaviorSubject<boolean>(false);
  private _dataLoadingSource = new BehaviorSubject<boolean>(false);

  view$ = this._viewSource.asObservable();
  entitySettingsCollapse$ = this._entitySettingsCollapseSource.asObservable();
  userSettingsCollapse$ = this._userSettingsCollapseSource.asObservable();
  dataLoading$ = this._dataLoadingSource.asObservable();

  changeView(view) {
    this._viewSource.next(view);
  }

  changeEntitySettingsCollapse(view) {
    this._entitySettingsCollapseSource.next(view);
  }

  changeUserSettingsCollapse(view) {
    this._userSettingsCollapseSource.next(view);
  }

  changeDataLoading(bool) {
    this._dataLoadingSource.next(bool);
  }

}