import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {App} from '../../../models/app';

export interface AppsState extends EntityState<App> {
  synced: boolean;
}

export const appAdapter = createEntityAdapter<App>({
  sortComparer: (a, b) =>
    a.createTime < b.createTime ? 1: -1
});

export const initialAppsState: AppsState = appAdapter.getInitialState({
  synced: false,
});