import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {AppsService} from '../../firestore/app.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {CreateAppAction, DeleteAppAction, AppsActionType, UpdateAppAction} from './app.action';

@Injectable()
export class AppsEffects {

  public create$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CreateAppAction>(AppsActionType.CREATE),
      mergeMap(action => {
        const {app, onSuccess, onFailure} = action.payload;
        return this.appsService.create(app).pipe(
          mergeMap(createdApp => createCallbackActions(onSuccess, createdApp)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public update$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<UpdateAppAction>(AppsActionType.UPDATE),
      mergeMap(action => {
        const {appId, app, onSuccess, onFailure} = action.payload;

        return this.appsService.update(appId, app).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public delete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeleteAppAction>(AppsActionType.DELETE),
      mergeMap(action => {
        const {appId, onSuccess, onFailure} = action.payload;
        return this.appsService.delete(appId).pipe(
          mergeMap(() => createCallbackActions(onSuccess, appId)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  constructor(private actions$: Actions, private appsService: AppsService) {}
}
