import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Survey} from '../../models/survey';
import { Firestore } from '@angular/fire/firestore';
import { doc, addDoc, collection, updateDoc, deleteDoc, CollectionReference } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})

export class SurveysService {

  constructor(
    private firestore: Firestore
  ) {}

  public create(survey: Partial<Survey>): Observable<Survey> {
    const createTime = new Date();
    const surveySurvey = {...survey, createTime};
    return from (
      addDoc(this.collectionReference(), surveySurvey)
    ).pipe(map(() => ({...surveySurvey} as Survey)))
  }                                                                                                                          

  public update(surveyId: string, survey: Partial<Survey>): Observable<void> {
    return from(
      updateDoc(doc(this.firestore, 'surveys', surveyId), survey)
    );
  }

  public delete(surveyId: string): Observable<void> {
    return from(
      deleteDoc(doc(this.collectionReference(), surveyId))
    );
  }

  public collectionReference() {
    return collection(this.firestore, 'surveys') as CollectionReference<Survey>;
  }

}