import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from './core/core.module';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SharedModule } from './shared/shared.module';


registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    SharedModule,
    BrowserModule, 
    IonicModule.forRoot({
      scrollAssist: false,
      scrollPadding: false
    }),
    AppRoutingModule,
    CoreModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
