import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {SuggestionsService} from '../../firestore/suggestions.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {CreateSuggestionAction, DeleteSuggestionAction, SuggestionsActionType, UpdateSuggestionAction} from './suggestion.action';

@Injectable()
export class SuggestionsEffects {

  public create$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CreateSuggestionAction>(SuggestionsActionType.CREATE),
      mergeMap(action => {
        const {suggestion, onSuccess, onFailure} = action.payload;
        return this.suggestionsService.create(suggestion).pipe(
          mergeMap(createdSuggestion => createCallbackActions(onSuccess, createdSuggestion)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public update$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<UpdateSuggestionAction>(SuggestionsActionType.UPDATE),
      mergeMap(action => {
        const {suggestionId, suggestion, onSuccess, onFailure} = action.payload;

        return this.suggestionsService.update(suggestionId, suggestion).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public delete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeleteSuggestionAction>(SuggestionsActionType.DELETE),
      mergeMap(action => {
        const {suggestionId, onSuccess, onFailure} = action.payload;
        return this.suggestionsService.delete(suggestionId).pipe(
          mergeMap(() => createCallbackActions(onSuccess, suggestionId)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  constructor(private actions$: Actions, private suggestionsService: SuggestionsService) {}
}
