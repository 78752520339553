import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { User } from 'src/app/models/users';
import { selectCurrentUser } from 'src/app/core/store/users/users.selector';
import { Capacitor } from '@capacitor/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-user-settings-projects',
  templateUrl: './user-settings-projects.page.html',
  styleUrls: ['./user-settings-projects.page.scss'],
})
export class UserSettingsProjectsPage implements OnInit {

  public currentUser$: Observable<User>;

  public native: boolean = false;
  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private store$: Store<{}>,
    public media: MediaMatcher,
    public ref: ChangeDetectorRef,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => ref.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (Capacitor.isNativePlatform() || this.mobileQuery.matches) {
      this.native = true
    }
  }

  public ngOnInit() {
    this.currentUser$ = this.store$.pipe(select(selectCurrentUser));
  }

}
