import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Response} from '../../models/response';
import { Firestore } from '@angular/fire/firestore';
import { doc, addDoc, updateDoc, deleteDoc, CollectionReference, collection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})

export class ResponsesService {

  constructor(
    private firestore: Firestore
  ) {}

  public create(surveyId: string, response: Partial<Response>): Observable<Response> {
    const createTime = new Date();
    const responseSurvey = {...response, createTime};
    return from (
      addDoc(this.collectionReference(surveyId), responseSurvey)
    ).pipe(map(() => ({...responseSurvey} as Response)))
  }   
  
  public update(surveyId: string, responseId: string, response: Partial<Response>): Observable<void> {
    return from(
      updateDoc(doc(this.firestore, `surveys/${surveyId}/responses`, responseId), response)
    );
  }

  public delete(surveyId: string, responseId: string): Observable<void> {
    return from(
      deleteDoc(doc(this.collectionReference(surveyId), responseId))
    );
  }

  public collectionReference(surveyId: string) {
    return collection(this.firestore, `surveys/${surveyId}/responses`) as CollectionReference<Response>;
  }

}