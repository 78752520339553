import { AppsState, initialAppsState } from './app/app.state';
import { initialResponsesState, ResponsesState } from './response/responses.state';
import { SurveysState, initialSurveysState } from './survey/survey.state';

// import { InvitationsState, initialInvitationsState } from './invitations/invitations.state';
import { UtilitiesState, initialUtilitiesState } from './utilities/utilities.state';
import { initialPeersState, PeersState } from './peers/peers.state';
import { CustomRouterReducerState } from './router/router.state';
import { initialUsersState, UsersState } from './users/users.state';
import { initialSuggestionsState, SuggestionsState } from './suggestion/suggestion.state';
import { initialProjectsState, ProjectsState } from './project/project.state';

export interface AppState {
  router: CustomRouterReducerState;
  users: UsersState;
  utilities: UtilitiesState;
  projects: ProjectsState;
  suggestions: SuggestionsState
  surveys: SurveysState
  responses: ResponsesState
  apps: AppsState
  peers: PeersState
  // invitations: InvitationsState
}

export function initialAppState(): AppState {
  return {
    router: null,
    users: initialUsersState,
    utilities: initialUtilitiesState,
    projects: initialProjectsState,
    suggestions: initialSuggestionsState,
    surveys: initialSurveysState,
    responses: initialResponsesState,
    apps: initialAppsState,
    peers: initialPeersState,
    // invitations: initialInvitationsState,
  };
}