import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {UsersService} from '../../firestore/users.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {CreateUserAction, DeleteUserAction, UsersActionType, UpdateUserAction} from './users.action';

@Injectable()
export class UsersEffects {

  public create$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CreateUserAction>(UsersActionType.CREATE),
      mergeMap(action => {
        const {user, onSuccess, onFailure} = action.payload;
        return this.usersService.create(user).pipe(
          mergeMap(createdUser => createCallbackActions(onSuccess, createdUser)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )}
  );

  public update$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<UpdateUserAction>(UsersActionType.UPDATE),
      mergeMap(action => {
        const {userId, user, onSuccess, onFailure} = action.payload;

        return this.usersService.update(userId, user).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  })

  public delete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeleteUserAction>(UsersActionType.DELETE),
      mergeMap(action => {
        const {userId, onSuccess, onFailure} = action.payload;
        return this.usersService.delete(userId).pipe(
          mergeMap(() => createCallbackActions(onSuccess, userId)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  });

  constructor(private actions$: Actions, private usersService: UsersService) {}
}
