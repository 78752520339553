import {Action} from '@ngrx/store';
import {Project} from '../../../models/project';

export enum ProjectsActionType {
  GET_SUCCESS = '[Projects] Get :: Success',

  CREATE = '[Projects] Create On Server',
  CREATE_SUCCESS = '[Projects] Add To Store',

  UPDATE = '[Projects] Update On Server',
  UPDATE_SUCCESS = '[Projects] Modify In Store',

  DELETE = '[Projects] Delete On Server',
  DELETE_SUCCESS = '[Projects] Remove From Store',

  SET_SYNCED = '[Projects] Set Synced',
  SET_LOADED = '[Projects] Set Loaded',

  CLEAR = '[Projects] Clear',
}

export class GetProjectsSuccessAction implements Action {
  public readonly type = ProjectsActionType.GET_SUCCESS;

  public constructor(public payload: {projects: Project[]}) {}
}

export class CreateProjectAction implements Action {
  public readonly type = ProjectsActionType.CREATE;

  public constructor(
    public payload: {
      project: Partial<Project>;
      onSuccess?: (project: Project) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class CreateProjectSuccessAction implements Action {
  public readonly type = ProjectsActionType.CREATE_SUCCESS;

  public constructor(public payload: {project: Project}) {}
}

export class UpdateProjectAction implements Action {
  public readonly type = ProjectsActionType.UPDATE;

  public constructor(
    public payload: {
      projectId: string;
      project: Partial<Project>;
      onSuccess?: (project: Project) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdateProjectSuccessAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {project: Project}) {}
}

export class DeleteProjectAction implements Action {
  public readonly type = ProjectsActionType.DELETE;

  public constructor(
    public payload: {
      projectId: string;
      onSuccess?: (projectId: string) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class DeleteProjectSuccessAction implements Action {
  public readonly type = ProjectsActionType.DELETE_SUCCESS;

  public constructor(public payload: {projectId: string}) {}
}

export class SetProjectsSyncedAction implements Action {
  public readonly type = ProjectsActionType.SET_SYNCED;

  public constructor(public payload: {synced: boolean}) {}
}

export class SetProjectsLoadedAction implements Action {
  public readonly type = ProjectsActionType.SET_LOADED;

  public constructor(public payload: {loaded: boolean}) {}
}

export class ClearProjectsAction implements Action {
  public readonly type = ProjectsActionType.CLEAR;
}

export type ProjectsAction =
  | GetProjectsSuccessAction
  | CreateProjectAction
  | CreateProjectSuccessAction
  | UpdateProjectAction
  | UpdateProjectSuccessAction
  | DeleteProjectAction
  | DeleteProjectSuccessAction
  | SetProjectsSyncedAction
  | SetProjectsLoadedAction
  | ClearProjectsAction;
