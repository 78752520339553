import { SetUsersLoadedAction } from './../../store/users/users.action';

import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, of, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {User} from '../../../models/users';
import {
  CreateUserSuccessAction,
  DeleteUserSuccessAction,
  UpdateUserSuccessAction,
} from '../../store/users/users.action';
import {selectUsersSynced} from '../../store/users/users.selector';
import {convertDocumentSnapshotToEntity} from '../../utils/firebase/convert-document-snapshot-to-entity';
import { Firestore, collectionChanges } from '@angular/fire/firestore';
import { collection, CollectionReference, query, DocumentChange, where } from '@angular/fire/firestore';
import { Auth, user } from '@angular/fire/auth';


// This Services is now injected in the workspace.component.ts in order to fire ngOnDestroy() when leaving the app
@Injectable({
  providedIn: 'root',
})

export class UsersSyncService implements OnDestroy {
  private subscription: Subscription;

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private store$: Store<{}>
  ) {}

  public init() {
    this.subscription = this.subscribeToUsersChanges();
  }

  private subscribeToUsersChanges(): Subscription {
    console.log('USERS SUBSCRIBE')
    return combineLatest([this.store$.pipe(select(selectUsersSynced)), user(this.auth)])
      .pipe(
        switchMap(([synced, user]) => {
          if (!synced || !user) {
            return of([]);
          }
          return collectionChanges<User>(
            query<User>(
              collection(this.firestore, "users") as CollectionReference<User>, where('uid', '==', `${user.uid}`)
            )
          )
        })
      )
      .subscribe((actions: DocumentChange<User>[]) => {
        if (!actions) {
          return
        } else {
        actions.forEach(action => {
          const user = convertDocumentSnapshotToEntity<User>(action.doc);

          switch (action.type) {
            case 'added':
              return this.store$.dispatch(new CreateUserSuccessAction({user}));
            case 'modified':
              return this.store$.dispatch(new UpdateUserSuccessAction({user}));
            case 'removed':
              return this.store$.dispatch(new DeleteUserSuccessAction({userId: user.id}));
          }
        });
        this.store$.dispatch(new SetUsersLoadedAction({loaded: true}))
      }
    });
  }

  public ngOnDestroy() {
    console.log('USERS SYNC DESTROY')
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}