import {Action} from '@ngrx/store';
import {User} from '../../../models/users';

export enum UsersActionType {
  GET_SUCCESS = '[Users] Get :: Success',

  CREATE = '[Users] Create On Server',
  CREATE_SUCCESS = '[Users] Add To Store',

  UPDATE = '[Users] Update On Server',
  UPDATE_SUCCESS = '[Users] Modify In Store',
  UPDATE_CUSTOMER_BRANCHES = '[Users] Update User Branches On Server',

  DELETE = '[Users] Delete On Server',
  DELETE_SUCCESS = '[Users] Remove From Store',

  SET_SYNCED = '[Users] Set Synced',
  SET_LOADED = '[Users] Set Loaded',
  SET_CURRENT_USER = '[Users] Set Current User',

  CLEAR = '[Users] Clear',
}

export class GetUsersSuccessAction implements Action {
  public readonly type = UsersActionType.GET_SUCCESS;

  public constructor(public payload: {users: User[]}) {}
}

export class CreateUserAction implements Action {
  public readonly type = UsersActionType.CREATE;

  public constructor(
    public payload: {
      user: Partial<User>;
      onSuccess?: (user: User) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class CreateUserSuccessAction implements Action {
  public readonly type = UsersActionType.CREATE_SUCCESS;

  public constructor(public payload: {user: User}) {}
}

export class UpdateUserAction implements Action {
  public readonly type = UsersActionType.UPDATE;

  public constructor(
    public payload: {
      userId: string;
      user: Partial<User>;
      onSuccess?: (user: User) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdateUserSuccessAction implements Action {
  public readonly type = UsersActionType.UPDATE_SUCCESS;

  public constructor(public payload: {user: User}) {}
}

export class DeleteUserAction implements Action {
  public readonly type = UsersActionType.DELETE;

  public constructor(
    public payload: {
      userId: string;
      onSuccess?: (userId: string) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdateUserBranchesAction implements Action {
  public readonly type = UsersActionType.UPDATE_CUSTOMER_BRANCHES;

  public constructor(
    public payload: {
      userId: string;
      branchId: string;
      branchName: string
      onSuccess?: () => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class DeleteUserSuccessAction implements Action {
  public readonly type = UsersActionType.DELETE_SUCCESS;

  public constructor(public payload: {userId: string}) {}
}

export class SetUsersSyncedAction implements Action {
  public readonly type = UsersActionType.SET_SYNCED;

  public constructor(public payload: {synced: boolean}) {}
}

export class SetUsersLoadedAction implements Action {
  public readonly type = UsersActionType.SET_LOADED;

  public constructor(public payload: {loaded: boolean}) {}
}

export class ClearUsersAction implements Action {
  public readonly type = UsersActionType.CLEAR;
}

export class SetCurrentUserAction implements Action {
  public readonly type = UsersActionType.SET_CURRENT_USER;

  public constructor(public payload: {currentUserId: string}) {}
}

export type UsersAction =
  | GetUsersSuccessAction
  | CreateUserAction
  | CreateUserSuccessAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | UpdateUserBranchesAction
  | DeleteUserAction
  | DeleteUserSuccessAction
  | SetUsersSyncedAction
  | SetUsersLoadedAction
  | ClearUsersAction
  | SetCurrentUserAction;
