import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/models/users';
import { select, Store } from '@ngrx/store';
import { MediaMatcher } from '@angular/cdk/layout';
import { Capacitor } from '@capacitor/core';
import { selectCurrentUser } from 'src/app/core/store/users/users.selector';
import { ViewService } from 'src/app/core/page/view.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {

  @Input() rootPage: any;  
  @Input() level = 0;

  public native: boolean = false;
  public collapse: boolean;
  public subscriptions = new Subscription();

  public currentUser$: Observable<User>;

  public mobileQuery: MediaQueryList;
  public gridQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private store$: Store<{}>,
    private modalController: ModalController,
    public ref: ChangeDetectorRef,
    public media: MediaMatcher,
    public viewService: ViewService,
  ) { 
    if (Capacitor.isNativePlatform()) {
      this.native = true
    }
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => ref.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.currentUser$ = this.store$.pipe(select(selectCurrentUser));
    this.subscriptions.add(this.subscribeToViewService())
  }

  public subscribeToViewService(): Subscription {
    return this.viewService.userSettingsCollapse$.subscribe(view => {
      this.collapse = view
    })
  }

  public dismiss() {
    this.modalController.dismiss(null, 'cancel');
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}

