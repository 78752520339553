import { selectRouterState, selectPreviousRouterUrl } from './core/store/router/router.selector';
import { ClearSurveysAction, SetSurveysSyncedAction } from './core/store/survey/survey.action';
import { SurveysSyncService } from './core/firestore/sync/survey-sync.service';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthSyncService } from './core/firestore/sync/auth-sync.service';
import { select, Store } from '@ngrx/store'
import { selectUtilityByTag } from './core/store/utilities/utilities.selector';
import { UtilitiesSyncService } from './core/firestore/sync/utilities-sync.service';
import { ClearUtilitiesAction, SetUtilitiesSyncedAction } from './core/store/utilities/utilities.action';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private store$: Store<{}>,
    private router: Router,
    private authSyncService: AuthSyncService,
    private utilitiesSyncService: UtilitiesSyncService,
    private surveysSyncService: SurveysSyncService
  ) {}

  public ngOnInit() {
    this.authSyncService.init();
    this.utilitiesSyncService.init();
    this.surveysSyncService.init();
    
    this.store$.dispatch(new SetUtilitiesSyncedAction({synced: true}));
    this.store$.dispatch(new SetSurveysSyncedAction({synced: true}));
  }

  public ngOnDestroy() {
    console.log('destroy app component')
    this.utilitiesSyncService.ngOnDestroy();
    this.surveysSyncService.ngOnDestroy();

    this.store$.dispatch(new ClearUtilitiesAction());
    this.store$.dispatch(new ClearSurveysAction());
  }

}
