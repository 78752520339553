import {Action} from '@ngrx/store';
import {App} from '../../../models/app';

export enum AppsActionType {
  GET_SUCCESS = '[Apps] Get :: Success',

  CREATE = '[Apps] Create On Server',
  CREATE_SUCCESS = '[Apps] Add To Store',

  UPDATE = '[Apps] Update On Server',
  UPDATE_SUCCESS = '[Apps] Modify In Store',

  DELETE = '[Apps] Delete On Server',
  DELETE_SUCCESS = '[Apps] Remove From Store',

  SET_SYNCED = '[Apps] Set Synced',
  SET_LOADED = '[Apps] Set Loaded',

  CLEAR = '[Apps] Clear',
}

export class GetAppsSuccessAction implements Action {
  public readonly type = AppsActionType.GET_SUCCESS;

  public constructor(public payload: {apps: App[]}) {}
}

export class CreateAppAction implements Action {
  public readonly type = AppsActionType.CREATE;

  public constructor(
    public payload: {
      app: Partial<App>;
      onSuccess?: (app: App) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class CreateAppSuccessAction implements Action {
  public readonly type = AppsActionType.CREATE_SUCCESS;

  public constructor(public payload: {app: App}) {}
}

export class UpdateAppAction implements Action {
  public readonly type = AppsActionType.UPDATE;

  public constructor(
    public payload: {
      appId: string;
      app: Partial<App>;
      onSuccess?: (app: App) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class UpdateAppSuccessAction implements Action {
  public readonly type = AppsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {app: App}) {}
}

export class DeleteAppAction implements Action {
  public readonly type = AppsActionType.DELETE;

  public constructor(
    public payload: {
      appId: string;
      onSuccess?: (appId: string) => void;
      onFailure?: (error: any) => void;
    }
  ) {}
}

export class DeleteAppSuccessAction implements Action {
  public readonly type = AppsActionType.DELETE_SUCCESS;

  public constructor(public payload: {appId: string}) {}
}

export class SetAppsSyncedAction implements Action {
  public readonly type = AppsActionType.SET_SYNCED;

  public constructor(public payload: {synced: boolean}) {}
}

export class ClearAppsAction implements Action {
  public readonly type = AppsActionType.CLEAR;
}

export type AppsAction =
  | GetAppsSuccessAction
  | CreateAppAction
  | CreateAppSuccessAction
  | UpdateAppAction
  | UpdateAppSuccessAction
  | DeleteAppAction
  | DeleteAppSuccessAction
  | SetAppsSyncedAction
  | ClearAppsAction;
