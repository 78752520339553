import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Project} from '../../models/project';
import { Firestore } from '@angular/fire/firestore';
import { doc, addDoc, collection, updateDoc, deleteDoc, CollectionReference } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})

export class ProjectsService {

  constructor(
    private firestore: Firestore
  ) {}

  public create(project: Partial<Project>): Observable<Project> {
    const createTime = new Date();
    const entityProject = {...project, createTime};
    return from (
      addDoc(this.collectionReference(), entityProject)
    ).pipe(map(() => ({...entityProject} as Project)))
  }                                                                                                                          

  public update(projectId: string, project: Partial<Project>): Observable<void> {
    return from(
      updateDoc(doc(this.firestore, 'projects', projectId), project)
    );
  }

  public delete(projectId: string): Observable<void> {
    return from(
      deleteDoc(doc(this.collectionReference(), projectId))
    );
  }

  public collectionReference() {
    return collection(this.firestore, 'projects') as CollectionReference<Project>;
  }

}