import { ProjectsEffects } from './project/project.effects';
import { SurveysEffects } from './survey/survey.effects';

// import { InvitationsEffects } from './invitations/invitations.effects';
// import { invitationsReducer } from './invitations/invitations.reducer';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { AppState, initialAppState } from './app.state';
import { commonMetaReducer } from './common/common.meta-reducer';
import { clearStateMetaReducer } from './logout/logout.reducer';
import { PeersEffects } from './peers/peers.effects';
import { peersReducer } from './peers/peers.reducer';
import { customRouterReducer } from './router/router.reducer';
import { UsersEffects } from './users/users.effects';
import { usersReducer } from './users/users.reducer';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {environment} from '../../../environments/environment';
import { utilitiesReducer } from './utilities/utilities.reducer';
import { UtilitiesEffects } from './utilities/utilities.effects';
import { suggestionsReducer } from './suggestion/suggestion.reducer';
import { SuggestionsEffects } from './suggestion/suggestion.effects';
import { surveysReducer } from './survey/survey.reducer';
import { responsesReducer } from './response/responses.reducer';
import { ResponsesEffects } from './response/responses.effects';
import { appsReducer } from './app/app.reducer';
import { AppsEffects } from './app/app.effects';
import { projectsReducer } from './project/project.reducer';


const reducers: ActionReducerMap<AppState> = {
  router: customRouterReducer,
  users: usersReducer,
  utilities: utilitiesReducer,
  projects: projectsReducer,
  suggestions: suggestionsReducer,
  surveys: surveysReducer,
  responses: responsesReducer,
  apps: appsReducer,
  peers: peersReducer,
  // invitations: invitationsReducer,
};

const metaReducers: MetaReducer<AppState>[] = [commonMetaReducer];

const effects = [
  UsersEffects,
  UtilitiesEffects,
  ProjectsEffects,
  SuggestionsEffects,
  SurveysEffects,
  ResponsesEffects,
  AppsEffects,
  PeersEffects,
  // InvitationsEffects,
];

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      initialState: initialAppState,
      metaReducers: [clearStateMetaReducer],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot(effects),
    environment.storeDevtools ? StoreDevtoolsModule.instrument({maxAge: 50, name: `kmtd-app NgRx Store`}) : [],
  ],
  declarations: [],
})
export class AppStoreModule {}
