import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectSuggestionIdRouterParam } from '../router/router.selector';
import { suggestionAdapter } from './suggestion.state';

export const selectSuggestionsState = (state: AppState) => state.suggestions;

export const selectAllSuggestions = createSelector(
  selectSuggestionsState,
  suggestionAdapter.getSelectors().selectAll
);

export const selectSuggestionsMap = createSelector(
  selectSuggestionsState,
  suggestionAdapter.getSelectors().selectEntities
);

export const selectSuggestionById = (suggestionId: string) =>
  createSelector(
    selectSuggestionsMap,
    suggestionsMap => suggestionsMap[suggestionId]
  );

export const selectCurrentSuggestion = createSelector(
  selectSuggestionsMap,
  selectSuggestionIdRouterParam,
  (suggestionsMap, suggestionId) => suggestionsMap[suggestionId]
);

export const selectSuggestionsSynced = createSelector(
  selectSuggestionsState,
  state => state.synced
);
