import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectSurveyIdRouterParam } from '../router/router.selector';
import { surveyAdapter } from './survey.state';

export const selectSurveysState = (state: AppState) => state.surveys;

export const selectAllSurveys = createSelector(
  selectSurveysState,
  surveyAdapter.getSelectors().selectAll
);

export const selectSurveysMap = createSelector(
  selectSurveysState,
  surveyAdapter.getSelectors().selectEntities
);

export const selectSurveyById = (surveyId: string) =>
  createSelector(
    selectSurveysMap,
    surveysMap => surveysMap[surveyId]
  );

export const selectCurrentSurvey = createSelector(
  selectSurveysMap,
  selectSurveyIdRouterParam,
  (surveysMap, surveyId) => surveysMap[surveyId]
);

export const selectSurveysSynced = createSelector(
  selectSurveysState,
  state => state.synced
);
