import { CreateProjectAction } from './../../../core/store/project/project.action';
import { Permission } from './../../../models/permissions';
import { Project } from './../../../models/project';
import { selectCurrentUserId, selectCurrentUser } from './../../../core/store/users/users.selector';
import { User } from './../../../models/users';
import { Capacitor } from '@capacitor/core';
import { selectAllApps } from './../../../core/store/app/app.selector';
import { App } from './../../../models/app';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, asNativeElements, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Gesture, GestureController, IonItem, IonReorderGroup, ItemReorderEventDetail, ModalController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { selectUtilityByTag } from 'src/app/core/store/utilities/utilities.selector';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss'],
})
export class ProjectCreateComponent implements OnInit {

//   @ViewChild('dropzoneA') dropzoneA: ElementRef;
//   @ViewChild('dropzoneB') dropzoneB : ElementRef;

//   @ViewChildren('appItem', {read: ElementRef }) items: QueryList<ElementRef>;

//   public gestureArray: Gesture[] = [];
//   public pulledApps = [];


//   // public myArray = Array.from(Array(20).keys())

//   // public currentUser$: Observable<User>
//   // public entityFormGroup: FormGroup;

//   public apps$: Observable<App[]>;
//   public phases$: Observable<any>;
//   public mainModules$: Observable<any>;

//   public mobileQuery: MediaQueryList;
//   private _mobileQueryListener: () => void;

//   constructor(
//     private store$: Store<{}>,
//     private modalController: ModalController,
//     private router: Router,
//     public ref: ChangeDetectorRef,
//     public media: MediaMatcher,
//     private gestureController: GestureController,
//   ) { 
//     this.mobileQuery = media.matchMedia('(max-width: 767px)');
//     this._mobileQueryListener = () => ref.detectChanges();
//     this.mobileQuery.addListener(this._mobileQueryListener);

//   }

//   public ngOnInit() {
//     this.apps$ = this.store$.select(selectAllApps);
//     this.phases$ = this.store$.select(selectUtilityByTag('phases'));
//     this.mainModules$ = this.store$.select(selectUtilityByTag('main-modules'));
//   }

//   public ngAfterViewInit() {
//     this.updateGestures();
//   }

//   updateGestures() {
//     this.pulledApps.map(a => a.destroy())
//     this.pulledApps = [];

//     const arr = this.items.toArray();

//     // this.apps$.pipe(take(1)).subscribe(apps => {


//       for (let i = 0; i < arr.length; i++) {
//         const item = arr[i];
//         // return
//         const drag = this.gestureController.create({
//           el: item.nativeElement,
//           threshold: 1,
//           gestureName: 'drag',
//           onStart: ev => {
//             item.nativeElement.style.transition = '';
//             item.nativeElement.style.fontWeight = 'bold';
//             // item.nativeElement.style.zIndex = '10'
//             this.ref.detectChanges();
//           },
//           onMove: ev => {
//             item.nativeElement.style.transform = `translate(${ev.deltaX}px, ${ev.deltaY}px)`;
//             item.nativeElement.style.zIndex = '10';
//             this.checkDropzone(ev.currentX, ev.currentY)
//           },
//           onEnd: ev => {
//             this.handleDrop(item, ev.currentX, ev.currentY, i);
//           }
//         })
//         drag.enable();
//         this.gestureArray.push(drag);
//       }

//   }

//   public checkDropzone(x, y) {
//     const dropA = this.dropzoneA.nativeElement.getBoundingClientRect();
//     const dropB = this.dropzoneB.nativeElement.getBoundingClientRect();

//     if (this.isInZone(x, y, dropA)) {
//       this.dropzoneA.nativeElement.style.backgroundColor = 'lightgrey';
//       // #1b92b2
//     } else {
//       this.dropzoneA.nativeElement.style.backgroundColor = 'white'
//     }

//     if (this.isInZone(x, y, dropB)) {
//       this.dropzoneB.nativeElement.style.backgroundColor = 'lightgrey';
//     } else {
//       this.dropzoneB.nativeElement.style.backgroundColor = 'white'
//     }

//   }

//   public isInZone(x, y, dropzone) {
//     if (x < dropzone.left || x >= dropzone.right) {
//       return false
//     }
//     if (y < dropzone.top || y >= dropzone.bottom) {
//       return false
//     }
//     return true
//   }

//   public handleDrop(item, x, y, i) {
//     const dropA = this.dropzoneA.nativeElement.getBoundingClientRect();
//     const dropB = this.dropzoneB.nativeElement.getBoundingClientRect();

//     if (this.isInZone(x, y, dropA) || this.isInZone(x, y, dropB)) {
//       item.nativeElement.remove();
//       // this.pulledApps.push(item);
//       this.dropzoneA.nativeElement.style.backgroundColor = 'transparent'
//       this.dropzoneB.nativeElement.style.backgroundColor = 'transparent'

//       this.apps$.pipe(take(1)).subscribe(apps => {
//         apps.forEach((app, a) => {
//           if (a === i) {
//             this.pulledApps.push(app);
//             this.ref.detectChanges()
//           }
//         })
//       });

//       console.log(this.pulledApps)

//     }


//   }

//   public dismiss() {
//     this.router.navigate(['workspace', 'project-list']);
//     this.modalController.dismiss(null, 'cancel');
//   }

// }

  public currentUserId$: Observable<string>;
  public currentUser$: Observable<User>;
  public numberRanges$: Observable<any>;
  public legalForms$: Observable<any>;
  public countries$: Observable<any>;
  public title: string;
  public native: boolean = false;
  public geocoder = new google.maps.Geocoder();

  public step = 0;
  public projectTitleFormGroup: FormGroup;
  public projectAddressFormGroup: FormGroup;
  public projectDetailsFormGroup: FormGroup;

  public bankInformation = [];
  public alternativeAddress = [];
  public contactInformation = [];

  constructor(
    private modalController: ModalController,
    private router: Router,
    public ref: ChangeDetectorRef,
    private store$: Store<{}>,
  ) {
    if (Capacitor.isNativePlatform()) {
      this.native = true
    }
  }

  public ngOnInit() {

    this.currentUser$ = this.store$.pipe(select(selectCurrentUser));
    this.countries$ = this.store$.pipe(select(selectUtilityByTag('country-list')));

    this.projectTitleFormGroup = new FormGroup({
      title: new FormControl(null, Validators.compose([Validators.required])),
    });

    this.projectAddressFormGroup = new FormGroup({
      street: new FormControl(null, Validators.compose([Validators.required])),
      number: new FormControl(null, Validators.compose([Validators.required])),
      zip: new FormControl(null, Validators.compose([Validators.required])),
      city: new FormControl(null, Validators.compose([Validators.required])),
      country: new FormControl(null, Validators.compose([Validators.required])),
    });

    // this.baseDataFormGroup = new FormGroup({
    //   name: new FormControl(null, Validators.compose([Validators.required])),
    //   street: new FormControl(null, Validators.compose([Validators.required])),
    //   number: new FormControl(null, Validators.compose([Validators.required])),
    //   zip: new FormControl(null, Validators.compose([Validators.required])),
    //   city: new FormControl(null, Validators.compose([Validators.required])),
    //   country: new FormControl(null, Validators.compose([Validators.required])),
    // });

    // this.contactDataFormGroup = new FormGroup({
    //   email: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
    //   phone: new FormControl(null, Validators.compose([Validators.required])),
    //   fax: new FormControl(null),
    // });

    // this.legalDataFormGroup = new FormGroup({
    //   type: new FormControl(null, Validators.compose([Validators.required])),
    //   taxId: new FormControl(null, Validators.compose([Validators.required])),
    //   courtRegistration: new FormControl(null),
    //   VATId: new FormControl(null),
    //   GLNId: new FormControl(null),
    // });

    // this.legalRepDataFormGroup = new FormGroup({
    //   legalRepTitle: new FormControl(null, Validators.compose([Validators.required])),
    //   legalRepFirstName: new FormControl(null),
    //   legalRepLastName: new FormControl(null, Validators.compose([Validators.required])),
    // });

    // this.bankDataFormGroup = new FormGroup({
    //   bankName: new FormControl(null, Validators.compose([Validators.required])),
    //   IBAN: new FormControl(null, Validators.compose([Validators.required])),
    //   BIC: new FormControl(null, Validators.compose([Validators.required])),
    // });

  }

  public saveProject(user: User) {

    console.log(user);
    
    let project: Project;

    if (this.projectAddressFormGroup.valid) {
      let address = `${this.projectAddressFormGroup?.value.street} ${this.projectAddressFormGroup?.value.number} ${this.projectAddressFormGroup?.value.zip} ${this.projectAddressFormGroup?.value.city}`;

      return new Promise((resolve, reject) => {
        this.geocoder.geocode({address: address}, function(results, status) {
          if (status == 'OK') {
            let lat = results[0].geometry.location.lat();
            let lng = results[0].geometry.location.lng();
            let location = new google.maps.LatLng(lat, lng);
            resolve([lat, lng, location])
          } else {
            resolve([null, null, null])
          }
        }.bind(this))
      }).then(async (res) => {

        let projectAddress = {
          ...this.projectAddressFormGroup.value,
          lat: res[0],
          lng: res[1]
        }

        project = {
          createdByUserId: user.id,
          createdByUserName: user.userName,
          createdByUserEmail: user.email,
          title: this.projectTitleFormGroup.value.title,
          projectAddress,
          permissions: {[user.uid]: Permission.ADMIN},
          modules: ['project-management', 'contract-management', 'engineering', 'equipment', 'personnel-management', 'logistics', 'design-management', 'supply-chain', 'commercial-management', 'quality-management', 'hse'],
        }
    
        this.store$.dispatch(new CreateProjectAction({
          project
        }))
        this.dismiss();

      })

    } else {

      project = {
        createdByUserId: user.id,
        createdByUserName: user.userName,
        createdByUserEmail: user.email,
        title: this.projectTitleFormGroup.value.title,
        projectAddress: this.projectAddressFormGroup.valid ? this.projectAddressFormGroup.value : null,
        permissions: {[user.uid]: Permission.ADMIN},
        modules: ['project-management', 'contract-management', 'engineering', 'equipment', 'personnel-management', 'logistics', 'design-management', 'supply-chain', 'commercial-management', 'quality-management', 'hse'],
      }

      this.store$.dispatch(new CreateProjectAction({
        project
      }))
      this.dismiss();

    }

  }

  public updateTitle(title) {
    this.title = title;
    this.ref.detectChanges()
  }

  public next() {
    this.step++;
    this.ref.detectChanges()
  }

  public prev() {
    this.step--;
    this.ref.detectChanges()
  }

  public skip(step) {
    switch(step) {
      // case 1:
      // this.baseDataFormGroup.reset()
      // break;
      // case 2:
      // this.contactDataFormGroup.reset()
      // break;
      // case 3:
      // this.legalDataFormGroup.reset()
      // break;
      // case 4:
      // this.legalRepDataFormGroup.reset()
      // break;
    }
    this.step++
  }

  public skipSave(user, numberRanges) {
    // this.bankDataFormGroup.reset();
    // this.saveEntity(user, numberRanges);
  }

  public dismiss() {
    this.router.navigate(['workspace', 'project-list'])
    this.modalController.dismiss();
  }

}
