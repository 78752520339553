import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {ProjectsService} from '../../firestore/projects.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {CreateProjectAction, DeleteProjectAction, ProjectsActionType, UpdateProjectAction} from './project.action';

@Injectable()
export class ProjectsEffects {

  public create$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CreateProjectAction>(ProjectsActionType.CREATE),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;
        return this.projectsService.create(project).pipe(
          mergeMap(createdProject => createCallbackActions(onSuccess, createdProject)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public update$: Observable<Action> = createEffect(() => { 
    return this.actions$.pipe(
      ofType<UpdateProjectAction>(ProjectsActionType.UPDATE),
      mergeMap(action => {
        const {projectId, project, onSuccess, onFailure} = action.payload;

        return this.projectsService.update(projectId, project).pipe(
          mergeMap(() => createCallbackActions(onSuccess)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  public delete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeleteProjectAction>(ProjectsActionType.DELETE),
      mergeMap(action => {
        const {projectId, onSuccess, onFailure} = action.payload;
        return this.projectsService.delete(projectId).pipe(
          mergeMap(() => createCallbackActions(onSuccess, projectId)),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    );
  })

  constructor(private actions$: Actions, private projectsService: ProjectsService) {}
}
