import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Peer} from '../../../models/peer';

export interface PeersState extends EntityState<Peer> {
  loaded: boolean;
  projectId: string;
  currentPeer: Peer;
}

export const peersAdapter = createEntityAdapter<Peer>({
  sortComparer: (a, b) => b.createTime < a.createTime ? 1: -1,
});

export const initialPeersState: PeersState = peersAdapter.getInitialState({
  loaded: false,
  projectId: null,
  currentPeer: null,
});
